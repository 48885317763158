import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/sharp-solid-svg-icons';
import { useMemo, useState } from 'react';
import CollaboratorList from './CollaboratorList';
import CollaboratorListContainer from './CollaboratorListContainer';
import useCollaboratorRemovalQueue from './hooks/useCollaboratorRemovalQueue';
import RemoveButton from './RemoveButton';
import ModalHeader from '@/designSystemComponents/Modal/ModalHeader';

interface RemoveCollaboratorsModalProps {
  selectedObjectIds: string[];
  closeModal: () => void;
}

export default function RemoveCollaboratorsModal ({ selectedObjectIds, closeModal }: RemoveCollaboratorsModalProps) {
  const loggedInUser = useLoggedInUser();

  const {
    selected,
    collaborators,
    teams,
    isLoading,
    addCollaborator,
    addTeam,
  } = useCollaboratorRemovalQueue(selectedObjectIds, loggedInUser?.id);

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const numberOfUserShares = useMemo((): number => {
    const numberOfIndividualUserShares = selected.filter(({ __typename }) => __typename === 'Share').length;
    const teamShares = selected.filter(({ __typename }) => __typename === 'TeamShare');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const numberOfTeamUserShares = teamShares.reduce((acc, curr: any): number => {
      const numberOfExternalMembers = curr.team.externalTeamMemberCount;
      const numberOfInternalMembers = curr.team.internalTeamMemberCount;

      return acc + numberOfExternalMembers + numberOfInternalMembers;
    }, 0);

    return numberOfIndividualUserShares + numberOfTeamUserShares;
  }, [collaborators, teams]);

  const shareWarning: boolean = useMemo((): boolean => {
    return numberOfUserShares > 800;
  }, [numberOfUserShares]);

  return (
    <div className='flex flex-col gap-4 grow'>
      <ModalHeader title='Remove Collaborators' />
      <CollaboratorListContainer
        collaborators={collaborators}
        isLoading={isLoading}
      >
        <CollaboratorList
          collaborators={collaborators}
          teams={teams}
          selected={selected}
          addCollaborator={addCollaborator}
          addTeam={addTeam}
          emptyStateMessage='No collaborators to remove'
        />
      </CollaboratorListContainer>
      <RemoveButton
        selected={selected}
        closeModal={closeModal}
        disabled={selected.length === 0 || shareWarning}
        setErrorMessage={setErrorMessage}
        setSuccessMessage={setSuccessMessage}
      />
      {shareWarning &&
        (
          <div className='text-pink'>
            <div className='font-medium'>
              <FontAwesomeIcon icon={faXmark} className='mr-2' /> Wow, that&apos;s a lot!
            </div>
            <div>
              You can&apos;t share that much at once. Try removing some collaborators or selecting fewer items.
            </div>
          </div>
        )}
      {successMessage && (
        <div className='flex gap-2 items-center font-medium effra-14 text-tertiary'>
          <FontAwesomeIcon icon={faCheck} />
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className='whitespace-pre-wrap effra-14 text-pink first-line:font-medium'>
          <FontAwesomeIcon icon={faXmark} className='inline mr-2' />
          {errorMessage}
        </div>
      )}
    </div>
  );
}
