import type { ModularObject } from '@/__generated__/types';

export type PermissionLevel = 'isOwner' | 'needsApproval' | 'notAllowed';

export const actionToStatus = {
  'Mark as Complete': 'Complete',
  'Mark as Waived': 'Waived',
  'Mark as In Progress': 'In Progress',
  'Mark as To Do': 'To Do',
};

export enum ListAction {
  MARK_AS_COMPLETE = 'Mark as Complete',
  MARK_AS_WAIVED = 'Mark as Waived',
  MARK_AS_IN_PROGRESS = 'Mark as In Progress',
  MARK_AS_TO_DO = 'Mark as To Do',
  SHIFT_DATES = 'Shift Dates',
  UPDATE_HOURS = 'Update Hours',
  UPDATE_POINTS = 'Update Points',
  UPDATE_ASSIGNEE = 'Update Assignee',
  UPDATE_OWNER = 'Update Owner',
  ADD_TO_PARENT = 'Add to Parent',
  ADD_COLLABORATORS = 'Add Collaborators',
  REMOVE_COLLABORATORS = 'Remove Collaborators',
  UPDATE_TO_PRIVATE_VISIBILITY = 'Update to Private Visibility',
  UPDATE_TO_COMPANY_VISIBILITY = 'Update to Company Visibility',
  DUPLICATE = 'Duplicate',
  DUPLICATE_TREE = 'Duplicate from Here Down',
  ENABLE_APPROVALS = 'Enable Approvals',
  DISABLE_APPROVALS = 'Disable Approvals',
  DELETE = 'Delete',
  // PUSH_TO_JIRA = 'Push to Jira',
}

export const LIST_ACTIONS = {
  [ListAction.MARK_AS_COMPLETE]: {
    drivers: true,
    builds: false,
  },
  [ListAction.MARK_AS_WAIVED]: {
    drivers: true,
    builds: false,
  },
  [ListAction.MARK_AS_IN_PROGRESS]: {
    drivers: true,
    builds: false,
  },
  [ListAction.MARK_AS_TO_DO]: {
    drivers: true,
    builds: false,
  },
  [ListAction.SHIFT_DATES]: {
    drivers: true,
    builds: true,
  },
  [ListAction.UPDATE_HOURS]: {
    drivers: true,
    builds: false,
  },
  [ListAction.UPDATE_POINTS]: {
    drivers: true,
    builds: false,
  },
  [ListAction.UPDATE_ASSIGNEE]: {
    drivers: true,
    builds: false,
  },
  [ListAction.UPDATE_OWNER]: {
    drivers: true,
    builds: true,
  },
  [ListAction.ADD_TO_PARENT]: {
    drivers: true,
    builds: true,
  },
  [ListAction.ADD_COLLABORATORS]: {
    drivers: true,
    builds: true,
  },
  [ListAction.REMOVE_COLLABORATORS]: {
    drivers: true,
    builds: true,
  },
  [ListAction.UPDATE_TO_PRIVATE_VISIBILITY]: {
    drivers: true,
    builds: true,
  },
  [ListAction.UPDATE_TO_COMPANY_VISIBILITY]: {
    drivers: true,
    builds: true,
  },
  [ListAction.DUPLICATE]: {
    drivers: true,
    builds: true,
  },
  [ListAction.DUPLICATE_TREE]: {
    drivers: true,
    builds: true,
  },
  [ListAction.ENABLE_APPROVALS]: {
    drivers: true,
    builds: true,
  },
  [ListAction.DISABLE_APPROVALS]: {
    drivers: true,
    builds: true,
  },
  [ListAction.DELETE]: {
    drivers: true,
    builds: true,
  },
  // [ListAction.PUSH_TO_JIRA]: {
  //   drivers: true,
  //   builds: false,
  // },
};

export interface ActionMessageProps {
  permissionMap: Record<PermissionLevel, ModularObject[]>;
  onClose: () => void;
}

export enum TemplateNameToIdMap {
  ANTENNA = 'e9d45a78-a0a9-4e65-b4db-b873ec7a3b0d',
  AVIONICS_SYSTEM = 'dfeaf70f-f280-481b-b749-7be6259b6099',
  COMPONENTS = 'bc4af180-4ba2-4aff-b7c8-0c687b80ad3c',
  CONTROL_INTERFACE = '8f83edae-5e6a-4019-9068-932d85354367',
  COOLING_SYSTEM = 'b7687f05-4c0a-45a8-a27d-938b2c6546c7',
  DEPLOYER = 'ac58e5b6-3d9e-4c0a-9ab7-8bce7d4e25ad',
  DISPLAY = '58620cee-5cd8-4daf-a6b1-cdf98d3d160c',
  ELECTRICAL_POWER_SYSTEM = 'a6f00a02-f3ed-4eb0-8bbe-ae9234094514',
  EFFECTOR = '4ecd4865-8371-42af-bc19-ffa7547edb25',
  FUEL_TANKS = '62936b3c-15b5-47dd-a82e-9a917ce1a2cd',
  GENERIC_HARDWARE = '70ec96c1-36f1-4a0e-80f4-ef3e9aad3374',
  GROUND_CONTACT = 'ec2459bf-4ab7-4fd4-8ffe-879c6679a991',
  L_MINUS_MILESTONE = 'd5ce3c7b-4776-47e0-aa14-796f3dd33784',
  MEMORY = '08c46647-bfb6-480f-9407-7a2ce0ab6a18',
  MICROPROCESSOR = '71c9ff32-c204-407b-9d76-d0ec8a4ea946',
  MILESTONES = '438716b0-68d5-4bac-81a7-40edb767274d',
  MISSION = 'e0656ed3-211e-4f9e-a6e9-eff6dd5008a2',
  MOTOR_CONTROLLER = '844266d8-a210-4f86-9c42-3ae3c93bad60',
  MOTOR = 'c9f8969a-3725-4a12-b74e-ee69c88daec2',
  PAYLOADS = '5054fea5-b25e-47be-b75a-c0a87632b5ea',
  PRINTED_CIRCUIT_BOARD = '26e3e836-a784-4c78-ae33-a5a51d41cd5b',
  PROGRAM = '1ffa9284-ea96-44d2-8a13-9d6f3b499d18',
  PROPULSION = '75895b6b-96d4-4418-9b67-0e73494d8341',
  RECEIVER = 'fb7e4d5f-cc84-47c7-b5b1-3bbee3e2378b',
  REQUIREMENTS = '9deecd59-41ce-4c70-83eb-5d720c9f0c6a',
  ROBOTIC_ARM = '5769ca0c-8ce2-432b-94ff-6a15943ddc9e',
  SENSOR = '7fb48478-4375-48d1-b5e4-0c2e485905a3',
  SEPARATION_MECHANISM = '922c0b30-744d-4b80-a105-ac973dc65ff2',
  SOFTWARE = 'e4b71e99-0a2a-4767-8f68-6d057c423264',
  SPACE_STATION_ACCOMMODATION = '1264e4b3-2170-47d7-9139-0012b2dd6fac',
  SPACECRAFT = '43f70f1b-9aec-4a5e-88d3-6a606248ec3a',
  TASKS = '33214714-592e-458f-8de6-85e8ac45d471',
  LAUNCH_VEHICLES = '167e6a65-da5f-46f6-afa9-172a78352f71',
  VALVE = '91ea641b-acb8-4024-bcd5-2e6f77d5bb0d',
  WIDGET = '8edff323-3ff9-4ca0-9591-ce557b00d73f',
}

export const DriverTemplateTypes = {
  'Task': '33214714-592e-458f-8de6-85e8ac45d471',
  'Milestone': '438716b0-68d5-4bac-81a7-40edb767274d',
  'Requirement': '9deecd59-41ce-4c70-83eb-5d720c9f0c6a',
  'Component': 'bc4af180-4ba2-4aff-b7c8-0c687b80ad3c',
};

export enum Permissions {
  OWNER = 'OWNER',
  EDITOR = 'EDITOR',
  VIEWER = 'VIEWER',
}
