import { Input } from '@/components/form';
import FormContainer from '@/components/FormContainer/FormContainer';
import { useUserSelectionContext } from '../UserSelection.context';

function EmailInput (): JSX.Element {
  const { userEmail, setUserEmail } = useUserSelectionContext();

  return (
    <div className='flex flex-col gap-2 mt-4'>
      <Input
        id='email'
        type='email'
        label='Email'
        placeholder='Enter email'
        value={userEmail}
        onChange={(event): void => {
          setUserEmail(event.target.value);
        }}
        className=''
      />
    </div>
  );
}

interface EmailInputStepProps {
  incrementStep: () => void;
  selectedAction: string;
}

export default function EmailInputStep ({ incrementStep, selectedAction }: Readonly<EmailInputStepProps>): JSX.Element {
  const { userEmail, onClose } = useUserSelectionContext();
  return (
    <FormContainer
      onClose={onClose}
      headerText={selectedAction}
      content={<EmailInput />}
      buttons={
        <>
          <button className='btn btn-primary-hollow grow'>Cancel</button>
          <button disabled={!userEmail} className='btn btn-primary' onClick={incrementStep}>Continue</button>
        </>
      }
    />
  );
}
