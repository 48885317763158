export interface ModalHeaderProps {
  title: string;
}

export default function ModalHeader ({ title }: ModalHeaderProps) {

  return (
    <header className='flex justify-between items-center mb-[16px]'>
      <h1 className='effra-20'>{title}</h1>
    </header>
  );
}
