import FormContainer from '@/components/FormContainer/FormContainer';
import { useUserSelectionContext } from '../../UserSelection.context';

interface UserErrorProps {
  onGoBack: () => void;
}

export default function UserError ({ onGoBack }: Readonly<UserErrorProps>): JSX.Element {
  const { userEmail, onClose } = useUserSelectionContext();
  return (
    <FormContainer
      handleSubmit={(): void => {
        onGoBack();
      }}
      headerText='Uh oh! User not found.'
      content={
        <div className='flex flex-col gap-2 mt-4'>
          <div>
            <div>We couldn&apos;t find a matching user with the email address:</div>
            <div className='font-medium'>{userEmail}</div>
          </div>
          <div>Please check the email address and try again.</div>
        </div>
      }
      onClose={onClose}
      buttons={<button onClick={onGoBack} className='btn btn-primary-hollow grow'>Go Back</button>}
      width='unset'
    />
  );
}
