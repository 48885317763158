import UpdatesColumn from '@/components/cards/UpdatesColumn/UpdatesColumn';
import UpdatesSection from '@/components/cards/UpdatesSection/UpdatesSection';
import DriverSection from '@/components/common/Drivers/DriverSection/DriverSection';
import DependencyApprovalWarning from '@/components/modals/CollaboratorDiscovery/AccessSettings/DependencyApprovalWarning';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { TemplateType } from '@/models/template.model';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/sharp-regular-svg-icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { memo, useMemo } from 'react';
import CustomFields from '../../CustomFields/CustomFields';
import NestingSection from '../../Nesting/NestingSection';
import AttributesSection from '../Card v2/Attributes';
import CardSection from '../Card v2/CardSection';
import { CommentSection } from '../Card v2/CommentSection/CommentSection';
import DetailsSection from '../Card v2/DetailsSection/DetailsSection';
import DependencySection from '../DependencySection/DependencySection';
import { FileUploadSection } from '../FileUploadSection/FileUploadSection';

// Content displayed beneath the header of the ObjectCard.
const ObjectCardContent = memo(function ObjectCardContent (): JSX.Element {
  const { showNewUpdatesSection } = useFlags();

  const loggedInUser = useLoggedInUser();
  const { objectCardData, activeModularObjectId } = useObjectCardContext();
  const [showDependencyApprovalWarning, setShowDependencyApprovalWarning] = useLocalStorage(
    'showDependencyApprovalWarning',
    true,
  );

  const isDriver = objectCardData?.template?.type === TemplateType.Driver;

  const isObjectEditor = (objectCardData?.shares ?? [])?.some((share) => {
    return share?.userId === loggedInUser?.id && share?.role?.toUpperCase() === 'EDITOR';
  });

  const isObjectOwner = objectCardData?.ownerId === loggedInUser?.id;

  const shouldShowCommentSection = useMemo(() => isObjectEditor || isObjectOwner, [isObjectEditor, isObjectOwner]);

  if (!activeModularObjectId) return null;

  return (
    <div className='flex flex-col bg-neutral-100'>
      <CardSection title='Details' className='pb-8'>
        <DetailsSection />
      </CardSection>
      <CardSection title='Attributes' className='pb-8'>
        <AttributesSection />
        <CustomFields />
      </CardSection>
      <CardSection title='Files & Links' className='pb-[16px]'>
        <FileUploadSection />
      </CardSection>
      {!isDriver && (
        <CardSection title='Nesting' className='pb-8'>
          <NestingSection />
        </CardSection>
      )}
      <CardSection title={isDriver ? 'Nested Drivers' : 'Drivers'} className='px-1 pb-8 w-full'>
        <DriverSection hardware={objectCardData} hardwareType='modular_object' />
      </CardSection>
      <CardSection
        title='Dependencies'
        className='pb-8'
        notice={showDependencyApprovalWarning && (
          <div className='flex flex-row col-span-2 justify-between items-center p-4 mt-3 font-effra text-[14px] h-[20px] bg-gray-80/20'>
            <DependencyApprovalWarning objectId={activeModularObjectId} className='font-normal text-[14px]' />
            <FontAwesomeIcon
              icon={faXmark}
              className='cursor-pointer text-zinc-400'
              onClick={() => setShowDependencyApprovalWarning(false)}
            />
          </div>
        )}
      >
        <DependencySection
          objectId={activeModularObjectId}
          templateId={objectCardData?.templateId}
          templateName={objectCardData?.template?.name}
        />
      </CardSection>
      {shouldShowCommentSection && (
        <CardSection title='Comments' className='pb-8'>
          <CommentSection />
        </CardSection>
      )}

      <CardSection title='Updates' className='pb-8'>
        <UpdatesColumn objectID={activeModularObjectId} objectType='modular_object' />
        {showNewUpdatesSection && <UpdatesSection objectID={activeModularObjectId} paginationInput={null} />}
      </CardSection>
    </div>
  );
});

export default ObjectCardContent;
