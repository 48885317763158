import ControlledDatePickerWithUpdate from '@/components/form/DatePickerInput/ControlledDatePickerWithUpdate';
import LminusDateCalc from '@/components/modules/calculators/LminusDateCalc';
import TotalMass from '@/components/modules/calculators/TotalMass';
import Checkbox from '@/components/modules/Checkbox';
import CollapsibleSection from '@/components/modules/CollapsibleSection';
import DisplayValue from '@/components/modules/DisplayValueController';
import Group from '@/components/modules/Group';
import ModuleTextInput from '@/components/modules/ModuleTextInput';
import MultiSelect from '@/components/modules/MultiSelect';
import RadioGroup from '@/components/modules/RadioGroup';
import Repeater from '@/components/modules/Repeater';
import SelectInput from '@/components/modules/SelectBoxInput';
import TextAreaInput from '@/components/modules/TextAreaInput';
import TimePicker from '@/components/modules/TimePickerModule';
import PerformanceCalculator from '../calculators/PerformanceCalc';
import NumberInput from '../NumberInput';

export const MODULE_TYPES = [
  'checkbox',
  'collapsibleSection',
  'column',
  'date',
  'displayValue',
  'dropdown',
  'group',
  'lminusCalc',
  'numberInput',
  'performanceCalc',
  'radio',
  'repeater',
  'textArea',
  'textInput',
  'timeInput',
  'totalMass',
  'multiSelect',
] as const;

export type ModuleType = typeof MODULE_TYPES[number];

export const MODULE_COMPONENT_MAP: Record<ModuleType, (props) => JSX.Element> = {
  checkbox: Checkbox,
  collapsibleSection: CollapsibleSection,
  column: null,
  date: ControlledDatePickerWithUpdate,
  displayValue: DisplayValue,
  dropdown: SelectInput,
  group: Group,
  numberInput: NumberInput,
  radio: RadioGroup,
  repeater: Repeater,
  textArea: TextAreaInput,
  textInput: ModuleTextInput,
  timeInput: TimePicker,
  totalMass: TotalMass,
  performanceCalc: PerformanceCalculator,
  lminusCalc: LminusDateCalc,
  multiSelect: MultiSelect,
};
