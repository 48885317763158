import type { ScheduleModularObjectFragment } from '@/graphql/fragments/modularObject.generated';
import { DriverStatus } from '@/models/driver.model';
import { DRIVER_NAME_TO_ABBR, TemplateDriverNames, type TemplateType } from '@/models/template.model';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

function getDriverStatus (driver: Partial<ScheduleModularObjectFragment>): Lowercase<DriverStatus> {
  if (!driver.status) return DriverStatus.ToDo.toLowerCase() as Lowercase<DriverStatus>;
  return driver.status.toLowerCase().replace('_', ' ') as Lowercase<DriverStatus>;
}

function getDriverCountByStatus (drivers: Array<Partial<ScheduleModularObjectFragment>>, status: DriverStatus): number {
  return drivers.filter((driver) => getDriverStatus(driver) === status.toLowerCase()).length;
}

function getSortedObjectsByTemplateType (
  objects: Array<Partial<ScheduleModularObjectFragment>>,
  type: TemplateType,
  sortBy: 'modifiedAt' | 'createdAt' = 'modifiedAt',
): Array<Partial<ScheduleModularObjectFragment>> {
  return objects
    .filter((obj) => obj.template?.type === type)
    .sort((a, b) =>
      dayjs(b[sortBy])
        .diff(dayjs(a[sortBy]))
    );
}

const getFilteredDrivers = (state) => {
  if (state.currentFilters.length === 0) {
    return [...state.milestones, ...state.requirements, ...state.tasks, ...state.components];
  }
  return state.currentFilters.flatMap((filter) => state[filter.toLowerCase()] || []);
};

function getDriverDates (driver: Partial<ScheduleModularObjectFragment>) {
  return {
    startDate: driver.startDate ?? driver.targetDate,
    targetDate: driver.targetDate ?? driver.startDate,
  };
}

function getAtRiskDriverCount (drivers: Array<Partial<ScheduleModularObjectFragment>>): number {
  const riskyDrivers = drivers.filter((driver) => {
    const status = getDriverStatus(driver);
    const { startDate, targetDate } = getDriverDates(driver);

    if (!startDate) return false;

    const getNotStarted = dayjs().isBetween(startDate, targetDate, 'day', '[]') &&
      status === DriverStatus.ToDo.toLowerCase();

    const getNotCompletedRisk = dayjs().isAfter(targetDate, 'day') && status !== DriverStatus.Complete.toLowerCase();

    return getNotStarted || getNotCompletedRisk;
  });

  return riskyDrivers.length;
}

function recursivelyGetScheduleTrees (
  fullSchedule: Array<Partial<ScheduleModularObjectFragment>>,
  topLevelIds: string[],
  visited: Set<string> = new Set<string>(),
): Array<Partial<ScheduleModularObjectFragment>> {
  if (topLevelIds.length === 0) return [];

  const scheduleTrees: Array<Partial<ScheduleModularObjectFragment>> = [];

  // Add the top-level objects themselves
  for (const id of topLevelIds) {
    if (!id || visited.has(id)) continue;
    visited.add(id);

    const currentObject = fullSchedule.find((obj) => obj.id === id);
    if (currentObject) {
      scheduleTrees.push(currentObject);

      // Get all descendants
      const children = fullSchedule.filter((obj) => obj.parent?.id === id);
      const childIds = children.map((child) => child.id).filter((id): id is string => Boolean(id));
      const childTrees = recursivelyGetScheduleTrees(fullSchedule, childIds, visited);
      scheduleTrees.push(...childTrees);
    }
  }

  return scheduleTrees;
}

function getFullDriverText (driverText: string): string {
  switch (driverText) {
    case DRIVER_NAME_TO_ABBR[TemplateDriverNames.Component]:
      return 'Component';
    case DRIVER_NAME_TO_ABBR[TemplateDriverNames.Task]:
      return 'Task';
    case DRIVER_NAME_TO_ABBR[TemplateDriverNames.Requirement]:
      return 'Requirement';
    case DRIVER_NAME_TO_ABBR[TemplateDriverNames.Milestone]:
      return 'Milestone';
    default:
      return driverText;
  }
}

export {
  getAtRiskDriverCount,
  getDriverCountByStatus,
  getDriverStatus,
  getFilteredDrivers,
  getFullDriverText,
  getSortedObjectsByTemplateType,
  recursivelyGetScheduleTrees,
};
