import { AI_PLATFORM_URL } from '@/util/constants';
import { env } from '@/util/env.utils';
import { getCookie } from 'cookies-next';
import { useCallback, useEffect, useRef, useState } from 'react';

// Interface definitions
interface ProgramItem {
  project_title: string;
  project_goal: string;
  id?: string;
}

interface ProgramOptions {
  items: ProgramItem[];
}

// Function to directly interact with localStorage without hooks
// This prevents reference equality issues
const saveToLocalStorage = (key: string, value: unknown) => {
  try {
    if (typeof window === 'undefined') return;
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.error('Error saving to localStorage:', e);
  }
};

const getFromLocalStorage = <T,>(key: string, defaultValue: T): T => {
  try {
    if (typeof window === 'undefined') return defaultValue;

    const item = window.localStorage.getItem(key);
    if (!item) return defaultValue;

    return JSON.parse(item) as T;
  } catch (e) {
    console.error('Error reading from localStorage:', e);
    return defaultValue;
  }
};

// API fetch function
async function fetchProgramSuggestions (): Promise<ProgramItem[]> {
  try {
    const res = await fetch(`${AI_PLATFORM_URL()}/api/v1/chat/schedule_options`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getCookie(env('NEXT_PUBLIC_JWT_COOKIE_NAME'))}`,
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! Status: ${res.status}`);
    }

    const json: ProgramOptions = await res.json();
    return json.items ?? [];
  } catch (error) {
    console.error('Error fetching programs:', error);
    return [];
  }
}

export default function useProgramSuggestions (skipInitialLoad: boolean = false): [() => Promise<void>, ProgramItem[]] {
  const isBrowser = typeof window !== 'undefined';

  const [programs, setPrograms] = useState<ProgramItem[]>(() => {
    return getFromLocalStorage<ProgramItem[]>('programCache', []);
  });

  const isLoadingRef = useRef(false);
  const initialLoadDoneRef = useRef(false);

  const fetchPrograms = useCallback(async () => {
    if (!isBrowser || isLoadingRef.current) return;

    isLoadingRef.current = true;
    try {
      const items = await fetchProgramSuggestions();

      setPrograms(items);
      saveToLocalStorage('programCache', items);
    } finally {
      isLoadingRef.current = false;
    }
  }, [isBrowser]);

  useEffect(() => {
    if (!isBrowser) return;

    if (skipInitialLoad || initialLoadDoneRef.current || programs.length > 0) {
      return;
    }

    initialLoadDoneRef.current = true;
    fetchPrograms();
  }, [skipInitialLoad, fetchPrograms, programs.length, isBrowser]);

  return [fetchPrograms, isBrowser ? programs : [] as ProgramItem[]];
}
