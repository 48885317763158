import { useUpdateAttributeValue } from '@/hooks/useUpdateAttributeValue';
import cx from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { RichText, type RichTextProps } from '../RichText/RichText';

interface HookFormInputProps extends React.ComponentProps<'textarea'> {
  name?: string;
  registration?: Record<string, unknown>;
  className?: string;
  disabled?: boolean;
  isEditable?: boolean;
  shouldShowToolbar?: boolean;
  onImageUpload?: (file: File) => Promise<string>;
}

export default function HookFormTextArea (
  { name, registration, className, placeholder, isEditable, shouldShowToolbar, onImageUpload }: HookFormInputProps,
): JSX.Element {
  const { register, setValue, getValues, getFieldState } = useFormContext();
  const { onChange: _, ...rest } = register(name, { ...(registration ?? {}) });
  const value = getValues(name);
  const { isDirty } = getFieldState(name);

  // Track the current editor for image uploads
  const editorRef = useRef(null);
  // Track if we have images that need to be persisted
  const hasImageToSave = useRef(false);
  // Keep local state for immediate display
  const [editorContent, setEditorContent] = useState(null);

  const content = useMemo(() => {
    try {
      return JSON.parse(value);
    } catch (e) {
      return value ?? '';
    }
  }, [value]);

  const { updateValue } = useUpdateAttributeValue();

  const handleChange: RichTextProps['onChange'] = ({ editor }) => {
    // Save reference to the editor
    editorRef.current = editor;

    const editorJson = editor.getJSON();
    const contentString = JSON.stringify(editorJson);

    // Check if content has images
    if (contentString.includes('"type":"image"')) {
      hasImageToSave.current = true;
    }

    // Update local state for immediate display
    setEditorContent(editorJson);

    // Update form value
    setValue(name, contentString, { shouldDirty: true });
  };

  // This function handles image uploads
  const handleImageUpload = async (file: File) => {
    if (!onImageUpload) return '';

    try {
      const url = await onImageUpload(file);

      // Mark that we have an image to save
      hasImageToSave.current = true;

      // Schedule a save operation on the next tick to ensure the editor has updated
      setTimeout(() => {
        // Get content directly from the editor if available
        if (editorRef.current) {
          const editorJson = editorRef.current.getJSON();
          const contentString = JSON.stringify(editorJson);

          // Update form value
          setValue(name, contentString, { shouldDirty: true });

          // Save immediately
          updateValue({ newFieldValue: contentString, attributeName: name })
            .then(() => {
              hasImageToSave.current = false;
            })
            .catch(err => console.error('Failed to save image content:', err));
        } else {
          // Fallback to form values if editor ref not available
          const currentValue = getValues(name);
          if (currentValue) {
            updateValue({ newFieldValue: currentValue, attributeName: name })
              .then(() => {
                hasImageToSave.current = false;
              })
              .catch(err => console.error('Failed to save image content:', err));
          }
        }
      }, 100); // Small delay to ensure editor has updated

      return url;
    } catch (error) {
      console.error('Image upload failed:', error);
      return '';
    }
  };

  const handleBlur = useCallback(async () => {
    // Get the current form value
    const currentValue = getValues(name);

    // Save if form is dirty OR we have images to save
    if (!isDirty && !hasImageToSave.current) {
      return;
    }

    await updateValue({ newFieldValue: currentValue, attributeName: name });

    // Reset image flag after saving
    hasImageToSave.current = false;
  }, [isDirty, name, updateValue, getValues]);

  // Use local state if available, otherwise use parsed content
  const displayContent = editorContent || content;

  // Force update when content changes to include images
  useEffect(() => {
    if (typeof content === 'object' && content !== null) {
      const contentString = JSON.stringify(content);
      if (contentString.includes('"type":"image"')) {
        hasImageToSave.current = true;
      }
    }
  }, [content]);

  return (
    <div
      data-testid='hook-form-text-area-wrapper'
      className={cx(
        'flex flex-col  flex-1 [&>div]:flex [&>div]:flex-1 list-disc',
      )}
      id={name}
      {...rest}
    >
      <RichText
        shouldShowToolbar={shouldShowToolbar}
        content={displayContent}
        isEditable={isEditable}
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={placeholder}
        className={`${className}`}
        onImageUpload={handleImageUpload}
      />
    </div>
  );
}
