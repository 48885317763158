import { makeVar, type TypePolicies } from '@apollo/client';
import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import type { UnreadModularObjects } from './reactiveVars/models/UnreadModularObjects.model';
import { markModularObjectUnread } from './reactiveVars/operations/mutations/unreadModularObjects';

export const localVariablesTypePolicies: TypePolicies = {
  ModularObject: {
    merge(existing, incoming, { mergeObjects, readField }) {
      markDateChangesAsUnread(existing, incoming, readField);
      return mergeObjects(existing, incoming);
    },
    fields: {
      isUnread: {
        read(_, { readField }) {
          const id: string = readField('id');
          return getModularObjectIsUnread(id);
        },
      },
    },
  },
};

// Local fields
export const unreadModularObjectsVar = makeVar<UnreadModularObjects>({});
export const streamedObjectUpdates = makeVar([]);

// isUnread – Boolean
// Whether an object has unread changes
export function getModularObjectIsUnread (id: string) {
  return unreadModularObjectsVar()[id] ?? false;
}

export const isUnreadVar = makeVar<boolean>(false);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function markDateChangesAsUnread (existing: any, incoming: any, readField: ReadFieldFunction) {
  const existingStartDate = readField('startDate', existing);
  const existingTargetDate = readField('targetDate', existing);
  const incomingStartDate = readField('startDate', incoming);
  const incomingTargetDate = readField('targetDate', incoming);

  const objectId: string = readField('id', existing);

  if (existingStartDate && incomingStartDate && existingStartDate !== incomingStartDate) {
    markModularObjectUnread(objectId);
  }
  if (existingTargetDate && incomingTargetDate && existingTargetDate !== incomingTargetDate) {
    markModularObjectUnread(objectId);
  }
}
