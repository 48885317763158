import { Loader } from '@/components/loader';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { Transition } from '@headlessui/react';
import ObjectCard from './Card/ObjectCard';

/**
 * For displaying the object card to a logged in user.
 */
export default function InternalObjectCard (): JSX.Element {
  const { isLoadingObjectCardData, objectCardData } = useObjectCardContext();

  if (isLoadingObjectCardData) {
    return (
      <div className='flex flex-1 justify-center items-center'>
        <Loader className='max-h-[4rem] max-w-[4rem]' />
      </div>
    );
  }

  return (
    <Transition
      as='div'
      show={true}
      enter={`transition-all ease-in-out duration-700 delay-[300ms]`}
      enterFrom='opacity-0 translate-y-6'
      enterTo='opacity-100 translate-y-0'
      leave='transition-all ease-in-out duration-300'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
      className='flex-1 w-full'
    >
      <ObjectCard forExternalLinks={false} object={objectCardData} />
    </Transition>
  );
}
