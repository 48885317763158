import { Button } from '@/app/components/Button/Button';
import { Dropdown, Input } from '@/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkLarge } from '@fortawesome/sharp-solid-svg-icons';
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import cx from 'classnames';
import Image from 'next/legacy/image';
import {
  type ChangeEvent,
  createContext,
  type PropsWithChildren,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

interface cardRowProps {
  custom?: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: any[];
  optionKey?: string;
  optionValueKey?: string;
  label?: string;
  id?: string;
  inputType?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  nested?: cardRowProps[];
  placeholder?: string;
  defaultValue?: string;
  prefix?: string;
  suffix?: string;
  width?: string;
}

interface CardModalProps {
  columns: cardRowProps[][];
  header?: string;
  isOpen: boolean;
  disableSubmit?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  submitText?: string;
  width?: string;
}

/**
 * @deprecated use `useModal` instead
 */
export function CardModal ({
  columns,
  header,
  isOpen,
  onClose = () => {},
  onSubmit,
  disableSubmit,
  submitText,
  width,
}: CardModalProps): JSX.Element {
  const ref = useRef(null);

  return (
    <Transition show={isOpen}>
      <Dialog className='absolute inset-0 z-[50]' onClose={onClose} initialFocus={ref}>
        <div className='flex justify-center items-center py-4 pr-4 min-h-screen text-center sm:p-0' data-testid='modal'>
          <TransitionChild
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='absolute inset-0 bg-black bg-opacity-60 transition-opacity' />
          </TransitionChild>
          <TransitionChild
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <DialogPanel
              className={cx(
                'relative font-effra px-4 py-4 text-left shadow-xl transform transition-all lg:max-w-4xl max-w-xl mx-auto md:px-8 md:py-6 bg-zinc-50',
                {
                  'w-full': !width,
                  [width]: Boolean(width),
                },
              )}
            >
              {onClose && (
                <label
                  className='absolute right-4 top-6 font-bold cursor-pointer md:right-8 text-primary font-effra z-[9999]'
                  onClick={onClose}
                  ref={ref}
                >
                  <Image src='/images/x-gray.svg' alt='X' height='12' width='12' />
                </label>
              )}
              <div>
                {!!header && <h4 className='pb-4 font-bold text-black'>{header}</h4>}
                <div
                  className={cx('grid grid-cols-1 md:gap-12 gap-6', {
                    'md:grid-cols-1': columns.length === 1,
                    'md:grid-cols-2': columns.length === 2,
                    'md:grid-cols-3': columns.length === 3,
                  })}
                >
                  {columns.map((column, idx) => (
                    <div className='space-y-6 w-full' key={`modal-column-${idx}`}>
                      {column.map((row, iidx) =>
                        row.nested
                          ? (
                            <div key={`modal-column-nested-${idx}-${iidx}`} className='space-y-1'>
                              {row.label && cardRenderLabel(row.label, row.id)}
                              <div
                                className={cx('grid md:gap-6 gap-1 justify-between', {
                                  'md:grid-cols-2 grid-cols-1': row.nested.length === 2,
                                  'md:grid-cols-3 grid-cols-1': row.nested.length === 3,
                                })}
                                id={row.id}
                              >
                                {row.nested.map((col, cidx) => (
                                  <div key={`modal-column-${idx}-row-${iidx}-col-${cidx}-div`}>
                                    {cardRenderProps(col, `modal-column-${idx}-row-${iidx}-col-${cidx}`)}
                                  </div>
                                ))}
                              </div>
                            </div>
                          )
                          : (
                            cardRenderProps(row, `modal-column-${idx}-row-${iidx}`)
                          )
                      )}
                      {idx === columns.length - 1 && !!submitText && (
                        <Button
                          id='modal-submit'
                          className='py-6 w-full text-gray-200 uppercase whitespace-normal effra-xs bg-primary'
                          doesRipple={true}
                          doesSpin={true}
                          onClick={onSubmit}
                          disabled={disableSubmit}
                        >
                          {submitText}
                        </Button>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
}

interface CardModalContextType {
  isModalOpen: boolean;
  showModal: (modalContent: ReactNode, modalProps?: Omit<CardModalV2Props, 'isOpen'>) => void;
  modalContent: ReactNode;
  clearContent: () => void;
  closeModal: () => void;
}
export const CardModalContext = createContext<CardModalContextType>(null);

interface CardModalProviderProps {
  children: ReactNode;
  values?: Partial<CardModalContextType>;
}

const CardModalProvider = ({ children, values }: CardModalProviderProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<ReactNode>(null);
  const [modalProps, setModalProps] = useState<Omit<CardModalV2Props, 'isOpen'>>(null);
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const clearContent = () => {
    setModalContent(null);
  };

  const showModal = useCallback((modalContent, modalProps) => {
    const defaultModalProps: Omit<CardModalV2Props, 'isOpen'> = {};
    const props = modalProps || defaultModalProps;

    setIsModalOpen(true);
    if (modalContent) {
      setModalContent(modalContent);
      setModalProps(props);
    }
  }, []);

  return (
    <CardModalContext.Provider value={{ isModalOpen, showModal, modalContent, clearContent, closeModal, ...values }}>
      <CardModalV2
        {...modalProps}
        isOpen={isModalOpen}
        handleClose={closeModal}
      >
        {modalContent}
      </CardModalV2>
      {children}
    </CardModalContext.Provider>
  );
};

export default CardModalProvider;

export interface CardModalV2Props {
  isOpen: boolean;
  showCloseIcon?: boolean;
  handleClose?: () => void;
  width?: string;
  className?: string;
  isMegaModal?: boolean;
}

// This modal is a work in progress.It is intended to replace the original modal component.
// The original modal makes the assumption that a modal will always contain/is a form.
// While that might be true now, its likely not always going to be true and we need that ability to be flexible which this modal provides

export function CardModalV2 ({
  isOpen,
  handleClose = () => {},
  showCloseIcon = true,
  children,
  className,
  isMegaModal = false,
}: PropsWithChildren<CardModalV2Props>): JSX.Element {
  const { clearContent } = useContext(CardModalContext);
  const ref = useRef(null);

  useEffect(() => {
    return clearContent;
  }, []);

  if (!isOpen) {
    return null;
  }

  const closeModalOnClickOutside = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    handleClose();
  };

  return (
    <div className='absolute inset-0 z-[50] p-[24px]'>
      <div className='bg-white/90 absolute inset-0' onClick={closeModalOnClickOutside} />
      <div
        className='flex justify-center items-center py-4 pr-4 min-h-screen text-center sm:p-0'
        data-testid='card-modal'
      >
        <section
          className={cx(
            'relative flex font-effra text-left shadow-heavy transform mx-auto bg-white overflow-y-hidden overflow-x-hidden',
            {
              'w-[70%] max-w-[1000px] p-4 md:px-8 md:py-6 lg:max-w-4xl': !isMegaModal,
              'max-w-[80vw] max-h-[80vh]': isMegaModal,
            },
            className,
          )}
        >
          {children}
          {showCloseIcon && (
            <label
              className='absolute right-4 top-6 font-bold cursor-pointer md:right-8 text-primary font-effra z-[9999]'
              onClick={handleClose}
              ref={ref}
            >
              <FontAwesomeIcon icon={faXmarkLarge} height='12' width='12' className='text-gray-40' />
            </label>
          )}
        </section>
      </div>
    </div>
  );
}

function cardRenderInput (props: cardRowProps) {
  if (props.inputType === 'textArea') {
    return (
      <textarea
        rows={6}
        placeholder={props.placeholder}
        onChange={props.onChange}
        defaultValue={props.defaultValue}
        className='px-0.5 w-full border-b-2 focus:border-black focus:outline-none font-effra'
      />
    );
  } else if (props.inputType === 'dropdown') {
    return (
      <Dropdown
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
        className={`w-${props.width || '44'} text-xxs whitespace-nowrap`}
        id={props.id}
        options={props.options}
        optionKey={props.optionKey}
        onChange={props.onChange}
      />
    );
  } else if (props.inputType === 'radioGroup') {
    return (
      <div className='flex flex-col gap-y-2 p-2 bg-white'>
        {props.options?.map((option, idx) => (
          <div key={`radio-option-${props.id}-${idx}`}>
            <label
              className='inline-block ml-2 font-medium'
              htmlFor={`radio-option-${props.id}-${idx}`}
            >
              <input
                type='radio'
                id={`radio-option-${props.id}-${idx}`}
                name={props.id}
                value={option?.[props.optionValueKey] || option}
                defaultChecked={props.defaultValue === (option?.[props.optionValueKey] || option)}
                onChange={props.onChange}
              />
              <span className='ml-2'>
                {option?.[props.optionKey] || option}
                {Boolean(option.subLabel) && <span className='text-sm font-light'>, {option.subLabel}</span>}
              </span>
            </label>
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <Input
        id={props.id}
        onChange={props.onChange}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        prefix={props.prefix}
        suffix={props.suffix}
        type={props.inputType}
        className={'pt-1 px-2'}
      />
    );
  }
}

function cardRenderProps (props: cardRowProps, key: string) {
  return (
    props.custom || (
      <div className='space-y-1' key={key}>
        {props.label && cardRenderLabel(props.label, props.id)}
        {cardRenderInput(props)}
      </div>
    )
  );
}

function cardRenderLabel (label: string, id: string) {
  return (
    <div className='flex items-center mb-2'>
      <label
        htmlFor={id}
        className='text-xs font-light uppercase whitespace-nowrap text-gray tracking-[2.4px]'
      >
        {label}
      </label>
    </div>
  );
}
