import { Dialog, DialogPanel, TransitionChild, Transition } from '@headlessui/react';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

export interface CommonCardProps {
  open: boolean;
  onClose: (callback?) => void;
  children: React.ReactNode;
  order: number;
  extraClass?: string;
  safeToLeave?: boolean;
}

const STACKING_GAP = 2.5; // in rem

// prompt the user if they try and leave with unsaved changes
export function Card (props: CommonCardProps): JSX.Element {
  const router = useRouter();
  const { open, onClose, children, order, extraClass, safeToLeave = true } = props;

  const handleWindowClose = useCallback((e: BeforeUnloadEvent) => {
    if (safeToLeave) return;
    e.returnValue = 'You have unsaved changes - are you sure you wish to leave this page?';
  }, [safeToLeave]);

  const handleBrowseAway = useCallback((url) => {
    const routeCallback = (): void => {
      void router.push(url);
    };
    // Do not prevent session timeout logout
    if (safeToLeave || url === '/logout') return;
    onClose(routeCallback);
    router.events.emit('routeChangeError');
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw `Route change to '${url}' was prevented (this error can be safely ignored).`;
  }, [safeToLeave]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleWindowClose);
    router.events.on('routeChangeStart', handleBrowseAway);

    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
      router.events.off('routeChangeStart', handleBrowseAway);
    };
  }, [handleWindowClose, handleBrowseAway]);

  return (
    <Transition show={open} unmount={true}>
      <Dialog onClose={onClose}>
        <TransitionChild
          as='div'
          className='fixed inset-y-0 right-0 z-20 w-full'
          style={{ paddingLeft: `${order * STACKING_GAP}rem`, width: `calc(100% - 18.5rem)` }}
          enter='transition-transform duration-700'
          enterFrom='translate-x-full'
          enterTo='translate-x-0'
          leave='transition-transform ease-out duration-700'
          leaveFrom='translate-x-0'
          leaveTo='translate-x-full'
        >
          <DialogPanel
            className={cx('h-screen bg-white shadow-2xl', {
              [extraClass]: !!extraClass,
            })}
          >
            {children}
          </DialogPanel>
        </TransitionChild>
      </Dialog>
    </Transition>
  );
}

export default Card;
