import { type ReactElement, useMemo, useState } from 'react';
import StatusTooltip from '../tooltip/StatusTooltip';

import { Transition } from '@headlessui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/sharp-regular-svg-icons';
import cx from 'classnames';
import { useEditContext } from '@/components/cards';
interface CollapsibleSectionProps {
  id?: string;
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
  lightHeader?: boolean;
  largeHeader?: boolean;
  className?: string;
  contentClass?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionButton?: ReactElement<any, any>;
  noPadding?: boolean;
  noGap?: boolean;
  columns?: number | object;
  showOnMount?: boolean;
  showStatusDot?: boolean;
  isDirty?: boolean;
}

export function CollapsibleSection ({
  id,
  title,
  children,
  lightHeader,
  largeHeader,
  className,
  contentClass,
  actionButton,
  noPadding,
  noGap,
  columns = 1,
  showOnMount = true,
  showStatusDot = false,
  isDirty,
}: CollapsibleSectionProps): JSX.Element {
  const [isShowing, setIsShowing] = useState(!!showOnMount);
  const { changes, requestedChanges } = useEditContext();
  let columnClasses = 'grid';
  if (!noGap) {
    columnClasses += ' lg:gap-y-8 gap-y-5 gap-x-5';
  }
  if (columns && typeof columns === 'number') {
    columnClasses += ` grid-cols-${columns}`;
  } else if (columns && typeof columns === 'object') {
    Object.entries(columns || {}).forEach(([key, value]) => {
      columnClasses += ` ${key}:grid-cols-${value}`;
    });
  }

  const tooltipStatus = useMemo(() => {
    if (Object.prototype.hasOwnProperty.call(changes ?? {}, 'imageId')) {
      return 'staged';
    }
    if (Object.prototype.hasOwnProperty.call(requestedChanges ?? {}, 'imageId')) {
      return 'waiting for approval';
    }
  }, [changes, requestedChanges]);

  return (
    <div id={id} className={className}>
      <div
        className={cx('flex items-center gap-[24px]', {
          'py-7 px-10': !className,
        })}
      >
        <button
          type='button'
          onClick={() => {
            setIsShowing(!isShowing);
          }}
          className='flex gap-2 items-center'
        >
          <FontAwesomeIcon icon={faChevronDown} className={cx('text-primary', { 'fa-rotate-270': !isShowing })} />
          <div
            className={cx('mr-1', {
              'font-bold font-effra': !lightHeader && !largeHeader,
              'label label-uppercase': lightHeader,
              'font-effra font-black': largeHeader,
            })}
          >
            <div className='flex items-center whitespace-nowrap'>
              {(showStatusDot || isDirty) && <StatusTooltip status={tooltipStatus} />}
              {title}
            </div>
          </div>
        </button>
        <div className='w-full h-0.5 bg-neutral-200 grow' />
        {Boolean(actionButton) && <div className='text-right shrink-0'>{actionButton}</div>}
      </div>
      <Transition
        as='div'
        show={isShowing}
        enter='transition duration-100 ease-out'
        enterFrom='transform scale-95 opacity-0'
        enterTo='transform scale-100 opacity-100'
        leave='transition duration-75 ease-out'
        leaveFrom='transform scale-100 opacity-100'
        leaveTo='transform scale-95 opacity-0'
      >
        <div
          className={cx(columnClasses, contentClass, 'items-end', {
            'px-10 pb-7': !noPadding,
          })}
        >
          {children}
        </div>
      </Transition>
    </div>
  );
}
