import { Input as DeprecatedInput } from '@/components/form';
import { Input } from '@/components/form/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/sharp-regular-svg-icons';
import cx from 'classnames';
import { type ChangeEventHandler, type ForwardedRef, forwardRef } from 'react';

interface MegaModalSearchInputProps {
  className?: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  containerClassName?: string;
}
/**
 * @deprecated use `src/components/form/Input.tsx` instead.  If you need to have it change based on edit perms, compose that functionality over top of this
 */
const MegaModalSearchInput = forwardRef(
  function MegaModalSearchInput (
    {
      containerClassName,
      className,
      value,
      onChange,
      placeholder = 'Search',
    }: MegaModalSearchInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ): JSX.Element {
    return (
      <div className={cx('flex pb-1 border-b border-black border-solid gap-[4px] shrink', containerClassName)}>
        <div className='flex items-center'>
          <FontAwesomeIcon icon={faMagnifyingGlass} className={className} />
        </div>
        <div className='grow'>
          <DeprecatedInput
            id='object-search'
            type='text'
            ref={ref}
            placeholder={placeholder}
            className={cx('shadow-[unset] border-b-0 text-lg pl-0 text-black placeholder-gray-400', className)}
            value={value}
            onChange={onChange}
          />
        </div>
      </div>
    );
  },
);

export const MegaModalSearchInputV2 = forwardRef(
  function MegaModalSearchInput (
    {
      containerClassName,
      className,
      value,
      onChange,
      placeholder = 'Search',
    }: MegaModalSearchInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ): JSX.Element {
    return (
      <div
        className={cx('flex gap-4 items-center pb-1 border-b border-black border-solid shrink', containerClassName)}
        data-testid='mega-modal-search-input-container'
      >
        <div className='flex items-center'>
          <FontAwesomeIcon icon={faMagnifyingGlass} className={className} />
        </div>
        <div className='grow'>
          <Input
            id='object-search'
            type='text'
            ref={ref}
            placeholder={placeholder}
            className={cx(
              'shadow-[unset] border-b-0 text-lg pl-0 text-black placeholder-gray-400 flex-1 outline-none effra-12',
              className,
            )}
            value={value}
            onChange={onChange}
          />
        </div>
      </div>
    );
  },
);

export default MegaModalSearchInput;
