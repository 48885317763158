import { Transition } from '@headlessui/react';
import cx from 'classnames';
import Image from 'next/legacy/image';
import { useEffect, useState } from 'react';

interface PasswordOverlayProps {
  passwordSubmissionError?: string;
  handlePasswordSubmit: (password: string) => void;
}

/**
 * Component: PasswordOverlay
 * @description - Component that renders a password overlay
 * @param {object} props - Component props
 */
export function PasswordOverlay ({
  passwordSubmissionError,
  handlePasswordSubmit,
}: Readonly<PasswordOverlayProps>): JSX.Element {
  // Password state variable
  const [password, setPassword] = useState('');
  // Show password state variable
  const [showPassword, setShowPassword] = useState(false);
  // Error state
  const [error, setError] = useState('');

  // Handles password submission
  const handleSubmit = (): void => {
    handlePasswordSubmit(password);
  };

  // Handles user input
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(e.target.value);
    // Clears any errors
    setError('');
  };

  useEffect(() => {
    setError(passwordSubmissionError);
  }, [passwordSubmissionError]);

  return (
    <div className='fixed top-0 right-0 bottom-0 left-0 backdrop-blur-sm'>
      <div className='fixed top-0 left-0 px-5 pt-8 lg:px-8'>
        <Image
          src='/images/wordmark-primary.svg'
          className='absolute top-0 left-0'
          alt='Logo'
          width={140}
          height={35}
        />
      </div>
      <div className='flex flex-col justify-center items-center h-full'>
        <div className='p-4 w-6/12 md:w-4/12 xl:w-3/12 2xl:w-2/12'>
          <div className='relative'>
            {/* Password input */}
            <input
              className={cx(
                'block w-full pl-4 pr-10 py-2 border-b bg-transparent focus:outline-none focus:border-tertiary font-black text-center placeholder:text-center',
                {
                  'border-warning': error,
                  'border-gray-400': !error,
                },
              )}
              placeholder='Enter password to view'
              type={showPassword ? 'text' : 'password'}
              onChange={handleChange}
            />
            {/* Inline button that shows and hides password input  */}
            <button
              className='flex absolute top-0 right-0 items-center px-2 h-full bg-transparent text-tertiary effra-xs'
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              {showPassword
                ? <Image src='/images/hide.svg' alt='Hide password' width={20} height={20} />
                : <Image src='/images/show.svg' alt='Show password' width={20} height={20} />}
            </button>
          </div>
          {/* Show submit button if password has been entered */}
          <Transition
            as='div'
            show={password.length > 0}
            className='overflow-hidden transition'
            enter='transition transition-all duration-500 ease-in'
            enterFrom='transform max-h-0 opacity-0'
            enterTo='transform max-h-screen opacity-100'
            leave='transition transition-all duration-500 ease-out'
            leaveFrom='transform max-h-screen opacity-100'
            leaveTo='transform max-h-0 opacity-0'
          >
            <button
              className={cx('block w-full mt-6 px-4 py-6 effra-xs', {
                'bg-primary text-white': !error,
                'bg-none text-warning border border-warning': error,
              })}
              onClick={handleSubmit}
            >
              {/* Shows error if incorrect password */}
              {error || 'View Item'}
            </button>
          </Transition>

          {/* Text giving with links giving option to login or request invite */}
          <div className='mt-7 text-center'>
            <p className='text-sm'>
              <a className='underline text-tertiary' href='/login'>
                Login
              </a>{' '}
              to collaborate.{' '}
              <a className='underline text-tertiary' href='https://integrate.co/#request-invite'>
                Request an invite
              </a>
              <br />
              to create an Integrate account.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
