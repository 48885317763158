import cx from 'classnames';
import type { ReactNode } from 'react';
import { useEffect } from 'react';
import StepperProvider, { useStepperContext } from './Stepper.context';

// Define the props that Step adds to any component
export interface StepContentProps {
  incrementStep: () => void;
  decrementStep: () => void;
  resetSteps: () => void;
  step: number;
}

// Define the Step component props
export interface StepProps<P extends object = Record<string, unknown>> {
  className?: string;
  stepNumber?: number;
  stepContent: React.ComponentType<P & StepContentProps>;
  // All other props will be passed to the stepContent component
  [key: string]: unknown;
}

// Define the Stepper component props
export interface StepperProps {
  className?: string;
  initialStep?: number;
  children: ReactNode;
}

export function Step<P extends object = Record<string, unknown>> ({
  className,
  stepContent: StepContent,
  stepNumber = 1,
  ...props
}: StepProps<P>): JSX.Element {
  const { step, register, increment, decrement, reset } = useStepperContext();

  useEffect(() => {
    register(stepNumber);
  }, [register, stepNumber]);

  const stepContentProps = {
    ...props,
    incrementStep: increment,
    decrementStep: decrement,
    resetSteps: reset,
    step,
  } as unknown as P & StepContentProps;

  return (
    <div
      className={cx('flex flex-col z-10 animate-fade-in', className, {
        // ! cannot get animation to fade out without flash of content in initial load
        'w-full': step === stepNumber,
        'z-[-1]': step !== stepNumber,
      })}
      data-testid='step'
    >
      {stepNumber === step && <StepContent {...stepContentProps} />}
    </div>
  );
}

export default function Stepper ({ children, className, initialStep = 1 }: Readonly<StepperProps>): JSX.Element {
  return (
    <div className={cx('relative flex flex-1 min-h-0', className)} data-testid='stepper'>
      <StepperProvider initialStep={initialStep}>{children}</StepperProvider>
    </div>
  );
}
