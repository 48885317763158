import { DRIVER_TYPES } from '@/models/template.model';
export function isValidDriverType (driverType: string): boolean {
  if (!driverType) return false;
  return DRIVER_TYPES.some(validType => validType.toLowerCase() === driverType.toLowerCase());
}

export function mapToValidDriverType (driverType?: string): string {
  if (!driverType) return 'Task';
  return DRIVER_TYPES.find(validType => validType.toLowerCase() === driverType.toLowerCase()) ?? 'Task';
}
