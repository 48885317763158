import { gql } from '@apollo/client';

export const typeDefs = gql`
  type ModularObject @client {
    id: ID
    name: String
    startDate: String
    targetDate: String
    isStreaming: Boolean
    template: Template
    deactivated: Boolean
    dependencies: [ModularObject]
    duration: Duration
    owner: User
    visibility: String
    children: [ModularObject]
    parent: ModularObject
    permission: String
  }

  type Template @client {
    id: ID
    name: String
    type: String
  }

  type Duration @client {
    startDate: String
    targetDate: String
  }

  type User @client {
    id: ID
    email: String
    organizationId: ID
  }

  extend type Query {
    streamingObjects: [ModularObject] @client
  }
`;
