import type { Dependency } from '@/__generated__/types';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import { patchDependencies, postDependencies } from '@/util/requests.functions';
import type { ApolloClient } from '@apollo/client';
import { markModularObjectUnread } from '@/apollo/reactiveVars/operations/mutations/unreadModularObjects';
import { constructMessageForToastError } from './errors.utils';

interface AddDependenciesParams {
  dependencies: Array<Partial<Dependency>>;
  objectId: string;
  apolloClient: ApolloClient<object>;
}

export async function addDependencies ({
  dependencies,
  objectId,
  apolloClient,
}: AddDependenciesParams): Promise<void> {
  await postDependencies(dependencies as Dependency[], objectId)
    .then(async (response) => {
      dependencies.forEach(dep => {
        markModularObjectUnread(dep?.modularObjectId);
        markModularObjectUnread(dep?.blockedById);
      });

      // Handle errors that come back during the successful request, because partial success is possible
      if (response.errors) {
        response.errors.forEach(error => {
          addToastError(error);
        });
      } else {
        addToastSuccess(dependencies.length > 1 ? 'Dependencies added' : 'Dependency added');
      }

      await apolloClient.refetchQueries({
        updateCache (cache) {
          cache.evict({ fieldName: 'getModularObjectByID' });
        },
      });

      // Refetches the queries for the gantt if observable
      await apolloClient.reFetchObservableQueries();
    })
    .catch((error) => {
      addToastError(constructMessageForToastError(error.error));
    });
}

interface EditDependenciesParams {
  dependencyToEdit: Partial<Dependency>;
  gapDays: string;
  selectedGapType: string;
  objectId: string;
  apolloClient: ApolloClient<object>;
}

export async function editDependencies ({
  dependencyToEdit,
  gapDays,
  selectedGapType,
  objectId,
  apolloClient,
}: EditDependenciesParams): Promise<void> {
  const response = await patchDependencies([{
    id: dependencyToEdit.id,
    calculatedGapDays: +gapDays,
    blockType: selectedGapType,
  }] as Partial<Dependency[]>, objectId);

  // Handle errors that come back during the successful request, because partial success is possible
  if (response.errors) {
    response.errors.forEach(error => {
      addToastError(error);
    });
  }

  await apolloClient.refetchQueries({
    updateCache (cache) {
      cache.evict({ fieldName: 'getModularObjectByID' });
    },
  });

  // Refetches the queries for the gantt if observable
  await apolloClient.reFetchObservableQueries();
}
