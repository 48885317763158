export default function SelectedProgramDisplay ({ programTitle }: { programTitle: string }) {
  return (
    <div className='flex flex-col gap-2 p-8 bg-white'>
      <div className='flex items-center gap-4'>
        <h2 className='label'>Selected Project:</h2>
      </div>
      <h2 className='font-normal effra-24'>{programTitle}</h2>
    </div>
  );
}
