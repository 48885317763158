import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import classnames from 'classnames';
import Image from 'next/legacy/image';
import { useState } from 'react';

interface DropdownMenuProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any;
  dropdownDirectionUp?: boolean;
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  optionKey?: any;
  prefix?: string;
  suffix?: string;
  className?: string;
  onSelect?: (itemName: string) => void;
}

export function DropdownMenu ({
  defaultValue,
  dropdownDirectionUp = false,
  id,
  options,
  className,
  prefix,
  suffix,
  onSelect,
}: DropdownMenuProps): JSX.Element {
  const [selectedItem, setSelectedItem] = useState(defaultValue);

  const _onChange = (name, action): void => {
    setSelectedItem(name);
    if (action) {
      action();
    }
    if (onSelect) {
      onSelect(name);
    }
  };

  return (
    <div
      className={classnames(className, 'relative', {
        'before:content-[attr(data-content-prefix)] before:mr-3 before:text-xs': !!prefix, // TODO: make text size dynamic here
        'after:content-[attr(data-content-suffix)] after:ml-1 after:text-xs': !!suffix,
      })}
      data-content-prefix={prefix}
      data-content-suffix={suffix}
      data-testid='dropdown-menu'
      id='dropdown-menu'
    >
      <Menu>
        <MenuButton className='flex relative items-center py-1 pr-10 pl-3 w-full text-left border focus:outline-none border-gray-dark tracking-[2.4px] min-h-4'>
          <span className='block text-black truncate effra-xs'>{selectedItem}</span>
          <span className='flex absolute inset-y-0 right-0 items-center pr-2 pointer-events-none'>
            <svg
              className='w-5 h-5 text-gray-400'
              viewBox='0 0 20 20'
              fill='none'
              stroke='currentColor'
            >
              <path
                d='M7 7l3-3 3 3m0 6l-3 3-3-3'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </span>
        </MenuButton>
        <Transition
          as='div'
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
          className={classnames('absolute mt-1 bg-white z-[9999] overflow-auto shadow-lg w-full', {
            'bottom-8': dropdownDirectionUp,
          })}
        >
          <MenuItems className='max-h-60 leading-6 sm:leading-5 focus:outline-none'>
            {options.map((item, idx) => {
              return (
                <MenuItem key={`dropdown-menu-option-${id}-${idx}`}>
                  <button
                    type='button'
                    className='flex justify-between items-center py-2.5 w-full transition hover:bg-gray-200 px-[0.6rem]'
                    onClick={() => {
                      _onChange(item.name, item.action);
                    }}
                  >
                    {item.icon && <Image src={item.icon} alt='Menu Item' height={12} width={12} />}
                    {!item.icon && (
                      <div className='w-4'>
                        {(selectedItem || defaultValue) === item.name && (
                          <Image src='/images/check-primary.svg' alt='Check Mark' width={14.56} height={11.2} />
                        )}
                      </div>
                    )}
                    <span
                      className={classnames('effra-xs block ml-3', `${item.color} || ''`)}
                      data-testid={`dropdown-${item.name}`}
                    >
                      {item.name}
                    </span>
                  </button>
                </MenuItem>
              );
            })}
          </MenuItems>
        </Transition>
      </Menu>
    </div>
  );
}
