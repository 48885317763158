import SelectedProgramDisplay from './SelectedProgramDisplay';
import TargetDateSelector from './TargetDateSelector';

interface ProgramConfirmationScreenProps {
  selectionState: {
    title: string;
    goal: string;
    targetDate: string;
  };
  closeModal: () => void;
}

export default function ProgramConfirmationScreen ({ selectionState, closeModal }: ProgramConfirmationScreenProps) {
  return (
    <div>
      <SelectedProgramDisplay
        // @ts-expect-error -- will be fixed in next PR
        icon={<i className='fa-solid fa-calendar' />}
        programTitle={selectionState.title}
      />
      <TargetDateSelector selectionState={selectionState} closeModal={closeModal} />
    </div>
  );
}
