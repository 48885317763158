import LoadingSpinner from '@/components/common/Drivers/DriverSection/LoadingSpinner';
import Stepper, { Step, type StepContentProps } from '@/components/Stepper/Stepper';
import useProgramSuggestions from '@/hooks/useProgramSuggestions';
import { useCallback, useEffect, useState } from 'react';
import ProgramConfirmationScreen from './components/ProgramConfirmationScreen';
import ProgramOption from './ProgramOption';

export interface ProgramSelectorProps extends StepContentProps {
  defaultSelectedId?: string;
  className?: string;
  setProjectTitleAndGoal: (projectData: { title: string; goal: string }) => void;
}

function ProgramSelector ({
  incrementStep,
  setProjectTitleAndGoal,
}: ProgramSelectorProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [, programs] = useProgramSuggestions();

  // Handle program selection
  const handleProgramSelect = useCallback((projectData: { title: string; goal: string }) => {
    incrementStep();
    setProjectTitleAndGoal(projectData);
  }, [incrementStep, setProjectTitleAndGoal]);

  // Update loading state when programs change
  useEffect(() => {
    setIsLoading(programs.length === 0);
  }, [programs]);

  return (
    <div className='h-full bg-white'>
      <div className='mb-[40px]'>
        <h2 className='mb-2 font-bold text-gray-900 effra-24 whitespace-nowrap'>
          What type of program would you like to create?
        </h2>
        <p className='mt-1 text-gray-500 effra-12'>
          We&apos;ll automatically generate a project program based on the preset below.
        </p>
      </div>

      <LoadingSpinner isLoading={isLoading} />
      <div className='flex flex-col gap-[16px]'>
        {programs.map((program, idx) => (
          <ProgramOption
            key={`program-${program.id || program.project_title}`}
            itemNum={idx}
            title={program.project_title}
            onClick={() =>
              handleProgramSelect({
                title: program.project_title,
                goal: program.project_goal,
              })}
            testId={`program-option-${program.id}`}
          />
        ))}
      </div>
    </div>
  );
}

interface SelectionState {
  title: string;
  goal: string;
  targetDate: string;
}

export default function SelectorModal ({ className, closeModal }: { className?: string; closeModal: () => void }) {
  const [selectionState, setSelectionState] = useState<SelectionState>({
    title: '',
    goal: '',
    targetDate: '',
  });

  return (
    <Stepper
      initialStep={1}
      className={`w-full p-[40px] min-h-[445px] min-w-[571px] shadow-lg rounded bg-white z-50 max-w-xl ${className}`}
    >
      <Step
        stepNumber={1}
        stepContent={ProgramSelector}
        setProjectTitleAndGoal={setSelectionState}
      />
      <Step
        stepNumber={2}
        stepContent={ProgramConfirmationScreen}
        closeModal={closeModal}
        selectionState={selectionState}
      />
    </Stepper>
  );
}
