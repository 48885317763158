import { ProfileImage } from '../ProfileImage/ProfileImage';
import RoleDesc from './RoleDesc';
import UserName from './UserName';
import UserProfileHover from './UserProfile/UserProfileHover';

import UserProvider from './User.context';

import type { User } from '@/__generated__/types';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import cx from 'classnames';
import type { UserByIdFragment } from './getUserById.generated';
import UserProfileDrawerTrigger from './UserProfile/UserProfileDrawerTrigger';
interface UserComponentProps {
  user: Partial<User | UserByIdFragment>;
  disableUserProfile?: boolean;
  disableUserProfileDrawer?: boolean;
  disableUserProfileHover?: boolean;
  display?: 'single' | 'large' | 'medium' | 'default' | 'small';
  action?: JSX.Element;
  imageOnly?: boolean;
  nameOnly?: boolean;
  noUserText?: string;
  className?: string;
  noRole?: boolean;
  showEmail?: boolean;
  size?: 'small' | 'medium' | 'large' | 'default';
  sizePx?: number;
}

function UserDetails ({ user, display, className, noRole, showEmail, action }: Readonly<UserComponentProps>) {
  const isDeleted = Boolean(user?.deletedAt);
  return (
    <div
      className={cx('overflow-hidden font-effra font-normal', { 'flex-col': display !== 'single' })}
    >
      <UserName className={className} />
      {!noRole && isDeleted && <div className='text-gray-60 effra-12'>(Removed User)</div>}
      {!noRole && !isDeleted && (
        <div className='flex overflow-hidden gap-1.5 items-center w-full shrink-0'>
          <RoleDesc />
        </div>
      )}
      {showEmail && (
        <div className='overflow-hidden truncate text-black/60 shrink-0'>
          {user?.email}
        </div>
      )}
      {action && <div>{action}</div>}
    </div>
  );
}

export default function UserComponent (
  {
    user,
    action,
    imageOnly,
    disableUserProfile,
    disableUserProfileDrawer,
    disableUserProfileHover,
    display = 'default',
    noUserText,
    className,
    noRole = false,
    showEmail = false,
    size,
    sizePx,
  }: Readonly<UserComponentProps>,
): JSX.Element {
  const loggedInUser = useLoggedInUser();

  const UserComponentContent = (
    <div
      className={cx(
        'flex items-center w-full space-x-3 overflow-hidden whitespace-nowrap text-left truncate',
        className,
        {
          'opacity-60': Boolean(user?.deletedAt),
        },
      )}
    >
      <UserProvider user={user} currentUser={loggedInUser} display={display} noUserText={noUserText}>
        <div
          className={cx('flex items-center space-x-3 truncate', {
            'shrink-0': imageOnly,
            // 'mr-[8px]': !imageOnly,
          })}
        >
          <ProfileImage user={user} size={size} sizePx={sizePx} />
          {!imageOnly &&
            (
              <UserDetails
                user={user}
                imageOnly={imageOnly}
                display={display}
                className={className}
                noRole={noRole}
                showEmail={showEmail}
                action={action}
                size={size}
              />
            )}
        </div>
      </UserProvider>
    </div>
  );

  if (!user || disableUserProfile || (disableUserProfileHover && disableUserProfileDrawer)) {
    return UserComponentContent;
  }

  if (disableUserProfileHover) {
    return (
      <UserProfileDrawerTrigger userId={user?.id} className='w-full truncate'>
        {UserComponentContent}
      </UserProfileDrawerTrigger>
    );
  }

  if (disableUserProfileDrawer) {
    return <UserProfileHover userId={user?.id}>{UserComponentContent}</UserProfileHover>;
  }

  return (
    <UserProfileHover userId={user?.id}>
      <UserProfileDrawerTrigger className='w-full truncate' userId={user?.id}>
        {UserComponentContent}
      </UserProfileDrawerTrigger>
    </UserProfileHover>
  );
}
