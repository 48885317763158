import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/sharp-solid-svg-icons';
import cx from 'classnames';

import type { MouseEventHandler } from 'react';

export interface CollaboratorsBadgeProps {
  nCollaborators: number;
  inverse?: boolean;
  type?: 'internal' | 'external';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  nullState?: JSX.Element;
}

export default function CollaboratorsBadge (
  { nCollaborators, onClick, inverse = false, type = 'internal', nullState = null }: Readonly<CollaboratorsBadgeProps>,
): JSX.Element {
  if (nCollaborators === 0) {
    return nullState;
  }

  return (
    <button type='button'
      title={`${nCollaborators} ${nCollaborators === 1 ? 'collaborator' : 'collaborators'}`}
      onClick={onClick}
      className={cx('effra-12 gap-1 flex items-center justify-center rounded px-1 min-w-[27px] h-[16px]', {
        'bg-primary': type === 'internal' && !inverse,
        'bg-tertiary': type === 'external' && !inverse,
        'bg-white': inverse,
        'text-primary': inverse && type === 'internal',
        'text-tertiary': inverse && type === 'external',
        'text-white': !inverse,
      })}
    >
      {nCollaborators}
      <FontAwesomeIcon icon={faUser} className='text-[9px]' />
    </button>
  );
}
