import Image from 'next/legacy/image';
import React, { type ReactElement, useState } from 'react';

import { Transition } from '@headlessui/react';

import classnames from 'classnames';

interface FilterSectionProps {
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionButton?: ReactElement<any, any>;
  noPadding?: boolean;
  required?: boolean;
}

export function FilterSection (props: FilterSectionProps) {
  const { title, children, className, actionButton, noPadding, required } = props;
  const [isShowing, setIsShowing] = useState(true);

  return (
    <div className={className}>
      <div
        className={classnames({
          'sm:columns-2 gap-10': actionButton,
          'py-7 px-10': !className,
        })}
      >
        <button
          onClick={() => {
            setIsShowing(!isShowing);
          }}
        >
          {isShowing && <Image src='/images/caret-down-primary.svg' alt='Caret Down' height={10} width={10} />}
          {!isShowing && <Image src='/images/caret-right-primary.svg' alt='Caret Right' height={10} width={10} />}
          <span className={'ml-3 effra-xs'}>
            {title} {required && <span className='text-secondary'>(Required)</span>}
          </span>
        </button>
        {Boolean(actionButton) && <div className='text-right'>{actionButton}</div>}
      </div>
      <Transition
        as='div'
        show={isShowing}
        enter='transition duration-100 ease-out'
        enterFrom='transform scale-95 opacity-0'
        enterTo='transform scale-100 opacity-100'
        leave='transition duration-75 ease-out'
        leaveFrom='transform scale-100 opacity-100'
        leaveTo='transform scale-95 opacity-0'
      >
        <div
          className={classnames('flex gap-16', 'items-end', {
            'px-10 pb-7': !noPadding,
          })}
        >
          {children}
        </div>
      </Transition>
    </div>
  );
}
