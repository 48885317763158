import type { HardwareIntegration } from '@/__generated__/types';
import { markModularObjectUnread } from '@/apollo/reactiveVars/operations/mutations/unreadModularObjects';
import { useAddChildMutation } from '@/components/common/Nesting/AddChild.generated';
import {
  GetChildrenForNestingColumnDocument,
  type GetChildrenForNestingColumnQuery,
} from '@/components/common/Nesting/GetChildrenForNestingColumn.generated';
import { useRemoveChildMutation } from '@/components/common/Nesting/RemoveChildMutation.generated';
import { addToastError, addToastSuccess } from '@/components/Toast/utils';
import { useMemo } from 'react';

export const useNestingMutations = (objectId: string) => {
  const [addChild] = useAddChildMutation({
    update: (cache, { data }) => {
      const newChildren = data?.createHardwareIntegration;

      try {
        const existingData = cache.readQuery<GetChildrenForNestingColumnQuery>({
          query: GetChildrenForNestingColumnDocument,
          variables: { modularObjectId: objectId },
        });

        if (existingData && 'getModularObjectByIDs' in existingData) {
          cache.writeQuery({
            query: GetChildrenForNestingColumnDocument,
            variables: { id: objectId },
            data: {
              getModularObjectByIDs: [{
                ...existingData.getModularObjectByIDs[0],
                children: [...existingData.getModularObjectByIDs[0].children, ...newChildren],
              }],
            },
          });
        }
      } catch (e) {
        console.warn('Failed to update GetChildrenForNestingColumn cache', e);
      }

      cache.modify({
        id: cache.identify({ __typename: 'ModularObject', id: objectId }),
        fields: {
          children(existingChildren = []) {
            return [...existingChildren, ...newChildren];
          },
        },
      });

      cache.modify({
        fields: {
          getTopLevelModularObjects(existing = {}, { readField }) {
            const hasParent = readField('parent', {
              __typename: 'ModularObject',
              id: objectId,
            });

            if (!hasParent) {
              return {
                ...existing,
                edges: [...(existing.edges || []), ...newChildren],
              };
            }
            return existing;
          },
        },
      });

      cache.evict({ fieldName: 'getModularObjectByID' });
      cache.evict({ fieldName: 'getTopLevelModularObjects' });
      cache.gc();
    },
    refetchQueries: [
      'GetObjectCardObject',
      'GetModularObjectChildren',
      'GetTopLevelModularObjects',
    ],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      const isApproval = data?.createHardwareIntegration?.every((integration) =>
        integration.__typename === 'HardwareIntegrationApproval'
      );
      const isIntegration = data?.createHardwareIntegration?.every((integration) =>
        integration.__typename === 'HardwareIntegration'
      );

      if (isApproval) {
        return addToastSuccess('Request to nest object(s) sent for approval.');
      }
      if (isIntegration) {
        markModularObjectUnread(objectId);
        data?.createHardwareIntegration?.forEach((child) => {
          markModularObjectUnread(child?.id);
        });

        return addToastSuccess('Successfully nested object(s)');
      }
      return addToastSuccess('Successfully nested object(s) and requested approval to nest object(s)');
    },
    onError: () => addToastError('Failed to nest object(s), please try again'),
  });

  const [removeChild] = useRemoveChildMutation({
    update: (cache, { data }) => {
      const removedIntegration = data?.deleteHardwareIntegration;

      try {
        const existingData = cache.readQuery<GetChildrenForNestingColumnQuery>({
          query: GetChildrenForNestingColumnDocument,
          variables: { modularObjectId: objectId },
        });

        if (existingData && existingData.getModularObjectByIDs?.[0]) {
          const remainingChildren = existingData.getModularObjectByIDs[0].children.filter(
            (child) => child.id !== (removedIntegration as HardwareIntegration).objectId,
          );

          cache.writeQuery({
            query: GetChildrenForNestingColumnDocument,
            variables: { modularObjectId: objectId },
            data: {
              getModularObjectByIDs: [
                {
                  ...existingData.getModularObjectByIDs[0],
                  children: remainingChildren,
                },
              ],
            },
          });
        }
      } catch (e) {
        console.warn('Failed to update cache after removing child:', e);
      }

      // Update the parent object's cache
      cache.modify({
        id: cache.identify({ __typename: 'ModularObject', id: objectId }),
        fields: {
          children(existingChildren = []) {
            return existingChildren.filter(
              child => child.id !== (removedIntegration as HardwareIntegration).objectId,
            );
          },
        },
      });

      // Update top level objects if needed
      cache.modify({
        fields: {
          getTopLevelModularObjects(existing = {}) {
            return {
              ...existing,
              edges: [...(existing.edges || [])],
            };
          },
        },
      });

      // Evict and garbage collect
      cache.evict({ fieldName: 'getModularObjectByID' });
      cache.evict({ fieldName: 'getTopLevelModularObjects' });
      cache.gc();
    },
    refetchQueries: [
      'GetObjectCardObject',
      'GetModularObjectChildren',
      'GetTopLevelModularObjects',
    ],
    awaitRefetchQueries: true,
  });

  return useMemo(() => ({ addChild, removeChild }), [addChild, removeChild]);
};
