import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/sharp-solid-svg-icons';
import cx from 'classnames';
import { type PropsWithChildren, useCallback, useState } from 'react';

interface SidenavSectionProps {
  title?: string;
  isCollapsedInitial?: boolean;
}

export default function SidenavSection ({
  children,
  title,
  isCollapsedInitial = false,
  ...props
}: PropsWithChildren<SidenavSectionProps>): JSX.Element {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(isCollapsedInitial);
  const toggleCollapse = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed]);

  return (
    <section
      data-testid='sidenav-section'
      className={cx(
        'transition-[grid-template-rows] duration-300 gap-y-[0px] grid',
        {
          'grid-rows-[min-content_1fr] gap-y-[8px]': !isCollapsed,
          'grid-rows-[min-content_0fr] hidden @expanded/sidenav:grid': isCollapsed,
        },
      )}
      {...props}
    >
      {title && (
        <button
          onClick={toggleCollapse}
          className='effra-14 px-[8px] h-[24px] hidden @expanded/sidenav:flex text-white/60 hover:bg-white/20 justify-between rounded-[2px] w-full group items-center transition-all'
        >
          {title}
          <FontAwesomeIcon
            icon={faChevronRight}
            rotation={!isCollapsed ? 90 : null}
            className='hidden transition-all group-hover:flex text-[10px]'
          />
        </button>
      )}
      {/* Section Divider, shown on minimized nav when section is not collapsed */}
      {!isCollapsed && <div className='@expanded/sidenav:hidden border-t border-white/20 w-full py-[8px]' />}
      <div
        className={cx('overflow-hidden transition-opacity duration-300 flex-col gap-[4px] flex', {
          'opacity-0': isCollapsed,
          'opacity-100': !isCollapsed,
        })}
      >
        {children}
      </div>
    </section>
  );
}
