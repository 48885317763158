import { useLoggedInSubscription, useLoggedInUserRole } from '@/hooks/useLoggedInUser';
import { useAppDispatch } from '@/state/hooks';
import { closeModularObjectModal } from '@/state/slices/session.slice';
import { HUMAN_FRIENDLY_SUBSCRIPTION_PLAN_NAMES } from '@/util/constants';
import {
  faArrowLeft,
  faCircleUser,
  faFileImport,
  faPuzzle,
  faShoppingCart,
  faUnlock,
  faUserCog,
  faUsers,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import SidenavTab from '../components/SidenavTab';

export default function SettingsTabs (): JSX.Element {
  const currentSubscription = useLoggedInSubscription();
  const { isAdmin, isSuper } = useLoggedInUserRole();
  const dispatch = useAppDispatch();
  const { pathname } = useRouter();
  const { smartsheetImport, jiraImport } = useFlags();
  const flags = useFlags();

  const planDisplayName = useMemo(() => {
    return HUMAN_FRIENDLY_SUBSCRIPTION_PLAN_NAMES[currentSubscription?.type] ?? null;
  }, [currentSubscription]);

  const shouldShowIntegrationsPage = flags.smartsheetImport || flags.smartsheetExport;
  const shouldShowDigiKeyPage = (isAdmin || isSuper) && flags.digiKeyPrototype;
  useEffect(() => {
    if (pathname.includes('/settings')) dispatch(closeModularObjectModal());
  }, [pathname, dispatch]);

  return (
    <div className='flex flex-col gap-[16px]'>
      {/* Leave Settings Button */}
      <SidenavTab
        keyName='Leave Settings'
        name='Settings'
        href='/'
        icon={<FontAwesomeIcon icon={faArrowLeft} />}
      />
      <div className='flex flex-col gap-[4px]'>
        <SidenavTab
          name='Profile'
          href='/settings/profile'
          isActive={pathname === '/settings/profile'}
          icon={<FontAwesomeIcon icon={faCircleUser} />}
        />
        <SidenavTab
          name='Company'
          href='/settings/company'
          isActive={pathname === '/settings/company'}
          icon={<FontAwesomeIcon icon={faUsers} />}
        />
        <SidenavTab
          name='Account'
          href='/settings/account'
          isActive={pathname === '/settings/account'}
          icon={<FontAwesomeIcon icon={faUserCog} />}
        />
        {shouldShowIntegrationsPage && (
          <SidenavTab
            name='Integrations'
            href='/settings/integrations'
            isActive={pathname === '/settings/integrations'}
            icon={<FontAwesomeIcon icon={faPuzzle} />}
          />
        )}
        {shouldShowDigiKeyPage && (
          <SidenavTab
            name='DigiKey Sync'
            href='/settings/digikey'
            isActive={pathname === '/settings/digikey'}
            icon={<FontAwesomeIcon icon={faShoppingCart} />}
          />
        )}
        {smartsheetImport && (
          <SidenavTab
            keyName='Smartsheet'
            name='Import Smartsheet'
            href='/import/smartsheet'
            isActive={pathname === '/import/smartsheet'}
            icon={<FontAwesomeIcon icon={faFileImport} />}
          />
        )}
        {jiraImport && (
          <SidenavTab
            keyName='Jira'
            name='Import Jira Project'
            href='/import/jira'
            isActive={pathname === '/import/jira'}
            icon={<FontAwesomeIcon icon={faFileImport} />}
          />
        )}

        <div className='flex'>
          <SidenavTab
            keyName='Plan'
            name={
              <div className='flex items-center gap-[16px]'>
                <div>Plan</div>
                <div className='effra-10'>{planDisplayName}</div>
              </div>
            }
            href='/settings/plan'
            isActive={pathname === '/settings/plan'}
            icon={<FontAwesomeIcon icon={faUnlock} />}
          />
        </div>
      </div>
    </div>
  );
}
