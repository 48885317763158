import { getFullDriverText } from '@/components/Schedule/AnalyticsDrawer/utils/driverUtils';
import InfoTooltip from '@/components/tooltip/InfoTooltip';
import { DRIVER_NAME_TO_ABBR } from '@/models/template.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt, faFlagSwallowtail, faRhombus, faShield } from '@fortawesome/sharp-solid-svg-icons';
import cx from 'classnames';
import { useMemo } from 'react';
import Pill from '../Pill';

export interface DriverPillProps {
  light?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>;
  type?: string;
}

export default function DriverPill ({ type, light = false }: Readonly<DriverPillProps>): JSX.Element {
  const driver = useMemo(() => {
    switch (type) {
      case 'L-Minus Milestone':
        return {
          icon: faRhombus,
          text: 'lmin',
        };
      case 'Milestones':
        return {
          icon: faRhombus,
          text: 'mile',
        };
      case 'Requirements':
        return {
          icon: faShield,
          text: 'req',
        };
      case 'Tasks':
        return {
          icon: faFlagSwallowtail,
          text: 'task',
        };
      case 'Components':
        return {
          icon: faBolt,
          text: DRIVER_NAME_TO_ABBR[type],
        };
      default:
        return {
          icon: faFlagSwallowtail,
          text: 'task',
        };
    }
  }, [type]);

  if (type == null) return null;

  return (
    <InfoTooltip
      message={getFullDriverText(driver.text)}
      placement='top'
    >
      <Pill
        data-testid='pill'
        className={cx(
          'inline-flex shrink border gap-[4px] px-[8px] py-[4px] items-center justify-center max-w-[56px] h-[24px]',
          {
            'border-black text-black': !light,
            'text-white border-white': light,
          },
        )}
      >
        <FontAwesomeIcon icon={driver.icon} /> {driver.text}
      </Pill>
    </InfoTooltip>
  );
}
