import type { TemplateSubTypes } from '@/__generated__/types';
import InfoTooltip from '@/components/tooltip/InfoTooltip';
import { DriverPillColor } from '@/models/driver.model';
import { TemplateSubType, UNABBREVIATED_DRIVER_SUB_TYPE } from '@/models/template.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt, faFlagSwallowtail, faRhombus, faShield } from '@fortawesome/sharp-solid-svg-icons';
import cx from 'classnames';
import Pill from '../Pill';

export interface SubDriverPillProps {
  color: DriverPillColor;
  type: TemplateSubTypes;
}

export default function SubDriverPill ({ color, type }: Readonly<SubDriverPillProps>): JSX.Element {
  const iconClassName = color === DriverPillColor.Black ? 'text-black' : 'text-white';

  const renderIcon = () => {
    switch (type) {
      case TemplateSubType.Milestone:
        return <FontAwesomeIcon icon={faRhombus} className={iconClassName} transform='shrink-6' />;

      case TemplateSubType.Requirement:
        return <FontAwesomeIcon icon={faShield} className={iconClassName} transform='shrink-6' />;

      case TemplateSubType.Task:
        return <FontAwesomeIcon icon={faFlagSwallowtail} className={iconClassName} transform='shrink-6' />;

      case TemplateSubType.Component:
        return <FontAwesomeIcon icon={faBolt} className={iconClassName} transform='shrink-6' />;

      default:
        return null;
    }
  };

  return (
    <InfoTooltip message={UNABBREVIATED_DRIVER_SUB_TYPE[type]} placement='left'>
      <Pill
        data-testid='sub-driver-pill'
        className={cx(
          'flex border items-center justify-center w-[18px] h-[18px] text-[16px]',
          {
            'text-black border-black': color === DriverPillColor.Black,
            'border-white': color === DriverPillColor.White,
          },
        )}
      >
        {renderIcon()}
      </Pill>
    </InfoTooltip>
  );
}
