import { KeyboardEventNames } from '@/util/constants';
import { Children, createElement, forwardRef, isValidElement, type MutableRefObject, useRef } from 'react';

interface InputWithPillsProps {
  children: React.ReactNode;
  className?: string;
  inputClassName?: string;
  placeholder?: string;
  searchTerm: string;
  onSearch: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onInputFocus: () => void;
  onInputBlur: () => void;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InputWithPills = forwardRef(function InputWithPills (
  {
    children,
    className =
      'flex flex-wrap gap-2 items-center p-2 w-full bg-white border resize-none border-gray-80 focus:border-b-primary:outline-none',
    inputClassName = 'bg-transparent focus:outline-none grow placeholder:text-neutral-400',
    placeholder = 'Add people or emails...',
    searchTerm,
    onInputChange,
    onSearch,
    onInputFocus,
    onInputBlur,
  }: Readonly<InputWithPillsProps>,
  ref: MutableRefObject<HTMLInputElement>,
): JSX.Element {
  const lastPillAddedRef = useRef(null);

  function handleInputKeydown (e) {
    if (
      e.key === KeyboardEventNames.Backspace && searchTerm === '' &&
      lastPillAddedRef.current !== document.activeElement
    ) {
      e.preventDefault();
      e.stopPropagation();
      lastPillAddedRef?.current?.focus();
    }

    if (e.key === KeyboardEventNames.Enter && searchTerm !== '') {
      onSearch(e);
      e.preventDefault();
      e.stopPropagation();

      ref?.current?.focus();
    }
  }

  return (
    <div className={className}>
      {Children.map(children, (child, index) => {
        if (index === Children.count(children) - 1) {
          return isValidElement(child) ? createElement(child.type, { ...child.props, ref: lastPillAddedRef }) : child;
        }
        return child;
      })}
      <input
        type='text'
        ref={ref}
        placeholder={placeholder}
        className={inputClassName}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        value={searchTerm}
        onChange={onInputChange}
        onKeyDown={handleInputKeydown}
      />
    </div>
  );
});
