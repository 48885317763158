import type * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import { DriverFragmentDoc, DriverChildrenFragmentDoc } from './driverFragment.generated';
import { ScheduleModularObjectChildrenFragmentDoc } from '../../../graphql/fragments/modularObject.generated';
import * as Apollo from '@apollo/client';
import { graphql } from 'msw'
const defaultOptions = {} as const;
export type GetAllDriversQueryVariables = Types.Exact<{
  filters: Types.InputMaybe<Types.DriverFilters>;
  childFilters: Types.InputMaybe<Types.Filters>;
}>;


export type GetAllDriversQuery = { __typename?: 'Query', getAllDrivers: Array<{ __typename?: 'ModularObject', id: string, name: string | null, startDate: any | null, targetDate: any | null, templateId: string | null, assigneeId: string | null, ownerId: string | null, visibility: string | null, deactivated: boolean | null, status: Types.DriverStatus | null, statusModuleId: string | null, approvalsEnabled: boolean | null, permission: Types.Permission | null, children: Array<{ __typename?: 'ModularObject', id: string, name: string | null, permission: Types.Permission | null, startDate: any | null, targetDate: any | null, modifiedAt: any | null, template: { __typename?: 'Template', id: string | null, type: Types.TemplateTypes | null, subType: Types.TemplateSubTypes | null, name: string | null } | null }> | null, owner: { __typename?: 'User', id: string, organizationId: string | null } | null, assignee: { __typename?: 'User', id: string, organizationId: string | null } | null, parent: { __typename?: 'ModularObject', id: string, name: string | null, template: { __typename?: 'Template', type: Types.TemplateTypes | null } | null } | null, dependencies: Array<{ __typename?: 'Dependency', id: string, modularObjectId: string | null, blockedById: string | null, blockType: Types.DependencyBlockType | null, activeAt: any | null, gapDays: number | null, gapMonths: number | null, gapYears: number | null }> | null, template: { __typename?: 'Template', id: string | null, name: string | null, type: Types.TemplateTypes | null, subType: Types.TemplateSubTypes | null } | null, shares: Array<{ __typename?: 'Share', id: string | null, role: Types.ShareRole | null, userId: string | null }> | null }> };


export const GetAllDriversDocument = gql`
    query GetAllDrivers($filters: DriverFilters, $childFilters: Filters) {
  getAllDrivers(filters: $filters) {
    ...Driver
    children(filters: $childFilters) {
      ...ScheduleModularObjectChildren
    }
  }
}
    ${DriverFragmentDoc}
${ScheduleModularObjectChildrenFragmentDoc}`;

/**
 * __useGetAllDriversQuery__
 *
 * To run a query within a React component, call `useGetAllDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDriversQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      childFilters: // value for 'childFilters'
 *   },
 * });
 */
export function useGetAllDriversQuery(baseOptions?: Apollo.QueryHookOptions<GetAllDriversQuery, GetAllDriversQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDriversQuery, GetAllDriversQueryVariables>(GetAllDriversDocument, options);
      }
export function useGetAllDriversLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDriversQuery, GetAllDriversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDriversQuery, GetAllDriversQueryVariables>(GetAllDriversDocument, options);
        }
export function useGetAllDriversSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllDriversQuery, GetAllDriversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllDriversQuery, GetAllDriversQueryVariables>(GetAllDriversDocument, options);
        }
export type GetAllDriversQueryHookResult = ReturnType<typeof useGetAllDriversQuery>;
export type GetAllDriversLazyQueryHookResult = ReturnType<typeof useGetAllDriversLazyQuery>;
export type GetAllDriversSuspenseQueryHookResult = ReturnType<typeof useGetAllDriversSuspenseQuery>;
export type GetAllDriversQueryResult = Apollo.QueryResult<GetAllDriversQuery, GetAllDriversQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetAllDriversQuery((req, res, ctx) => {
 *   const { filters, childFilters } = req.variables;
 *   return res(
 *     ctx.data({ getAllDrivers })
 *   )
 * })
 */
export const mockGetAllDriversQuery = (resolver: Parameters<typeof graphql.query<GetAllDriversQuery, GetAllDriversQueryVariables>>[1]) =>
  graphql.query<GetAllDriversQuery, GetAllDriversQueryVariables>(
    'GetAllDrivers',
    resolver
  )
