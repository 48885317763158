import type HookFormInput from '@/components/modules/HookFormInput';
import { forwardRef } from 'react';
import { useController } from 'react-hook-form';
import DatePickerInput, { type ParentRangeColor } from './DatePickerInput';

export interface ControlledDatePickerProps {
  id?: string;
  className?: string;
  name: string;
  startDate?: Date;
  endDate?: Date;
  parentStartDate?: Date;
  parentEndDate?: Date;
  parentRangeColor?: ParentRangeColor;
  placeholder?: string;
  inputProps?: Partial<React.ComponentProps<typeof HookFormInput>> & {
    onChange?: (dateString: string) => void;
  };
}

function ControlledDatePicker (
  { id, name, inputProps = {}, ...props }: ControlledDatePickerProps,
  ref: React.Ref<HTMLInputElement>,
): JSX.Element {
  const { field, fieldState } = useController({ name });
  const handleChange = inputProps?.onChange ?? field.onChange;

  return (
    <DatePickerInput
      id={id || name}
      error={fieldState.error?.message}
      ref={ref}
      {...props}
      {...inputProps}
      {...field}
      onChange={handleChange}
    />
  );
}

export default forwardRef(ControlledDatePicker);
