import { setCookie } from 'cookies-next';

import type {
  Dependency,
  Diff,
  File as FileModel,
  HardwareIntegration,
  ModularObject,
  Notification,
  ObjectToAccess,
  ObjectToActivateLink,
  ObjectToLink,
  Organization,
  Share,
  Template,
  User,
  UserPreferences,
} from '@/__generated__/types';
import type { SignupUserModel } from '@/models';
import { type CalcModel, type PerformanceCalc } from '@/models/calc.model';
import type { IntegrationDecision } from '@/models/integration.model';
import { API_URL, isHTTPS } from './constants';
import { env } from './env.utils';

export async function request (
  url: string,
  verb: string,
  includeCredentials: boolean,
  body?: string | FormData,
  cache: RequestCache = 'no-cache',
  headers?: Record<string, string>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  let impersonationEmail;
  if (typeof window !== 'undefined') {
    impersonationEmail = localStorage.getItem('impersonation');
  }
  const response = await fetch(url, {
    method: verb,
    mode: 'cors',
    cache,
    credentials: includeCredentials ? 'include' : undefined,
    headers: {
      ...(impersonationEmail ? { 'x-impersonate': impersonationEmail } : {}),
      ...headers,
    },
    body,
  });

  if (!response.ok) {
    let responseJson;
    try {
      responseJson = await response.json();
    } catch (_) {}
    // eslint-disable-next-line no-throw-literal,@typescript-eslint/no-throw-literal
    throw {
      name: response.statusText,
      code: response.status,
      error: responseJson?.error,
      message: responseJson?.message,
    };
  }

  try {
    const responseJson = await response.json();
    if (Boolean(responseJson) && 'data' in responseJson) {
      return responseJson.data;
    }
    return responseJson;
  } catch (_) {
    return response.ok;
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function callLogin (user): Promise<any> {
  const response = await request(`${API_URL()}/rest/v1/auth/login`, 'POST', true, JSON.stringify(user));

  return response;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function loginWithKeycloak (): Promise<any> {
  const response = await request(`${API_URL()}/rest/v1/auth/sso/keycloak`, 'GET', false);

  if (response.token) {
    setCookie(env('NEXT_PUBLIC_JWT_COOKIE_NAME'), response.token, {
      expires: new Date(response.expire),
      secure: isHTTPS(),
      domain: env('NEXT_PUBLIC_COOKIE_DOMAIN'),
      sameSite: 'lax',
    });
  }

  return response;
}

export async function callRefresh (): Promise<string> {
  const response = await request(`${API_URL()}/rest/v1/auth/refresh`, 'GET', true);

  setCookie(env('NEXT_PUBLIC_JWT_COOKIE_NAME'), response.token, {
    expires: new Date(response.expire),
    secure: isHTTPS(),
    domain: env('NEXT_PUBLIC_COOKIE_DOMAIN'),
    sameSite: 'lax',
  });

  return response.token;
}

export async function callLogout (): Promise<void> {
  return await request(`${API_URL()}/rest/v1/auth/logout`, 'GET', true);
}

export async function postCalc (calcs: CalcModel[]): Promise<CalcModel[]> {
  const cleanedCalcs: CalcModel[] = calcs?.map((calc) => ({
    ...calc,
    apogee: calc.apogee || 185,
    perigee: calc.perigee || 185,
    inclination: calc.inclination,
  }));
  const data = await request(
    `${API_URL()}/rest/v1/calculators/performance/legacy`,
    'POST',
    true,
    JSON.stringify(cleanedCalcs),
  );
  return data.calcs;
}

export async function postCalcModObject (calcs: PerformanceCalc[]): Promise<Record<string, number>> {
  const cleanedCalcs: PerformanceCalc[] = calcs?.map((calc) => ({
    ...calc,
    apogee: calc.apogee || 185,
    perigee: calc.perigee || 185,
    inclination: calc.inclination,
  }));
  const data = await request(
    `${API_URL()}/rest/v1/calculators/performance`,
    'POST',
    true,
    JSON.stringify(cleanedCalcs),
  );
  return data.calcs;
}

export async function getUserById (userId: string): Promise<User> {
  return await request(`${API_URL()}/rest/v1/users/${userId}`, 'GET', true, undefined, 'default');
}

export async function deleteUsers (users?: User[]): Promise<void> {
  return await request(`${API_URL()}/rest/v1/users`, 'DELETE', true, JSON.stringify(users));
}

export async function postUsers (users: User[]): Promise<User> {
  const data = await request(`${API_URL()}/rest/v1/users`, 'POST', true, JSON.stringify(users));
  return data.users[0];
}

export async function putUser (user: User): Promise<User> {
  const data = await request(`${API_URL()}/rest/v1/users`, 'PUT', true, JSON.stringify(user));
  return data.users[0];
}

export async function getUserPreferences (): Promise<UserPreferences> {
  const data = await request(`${API_URL()}/rest/v1/users/preferences`, 'GET', true);
  return data.preferences;
}

export async function updateOrders (body: string): Promise<void> {
  const data = await request(
    `${API_URL()}/rest/v1/digikey/orders`,
    'POST',
    true,
    body,
    'default',
    { 'Content-Type': 'application/json' },
  );
  return data.status;
}

export async function postUserPreferences (
  preferences: UserPreferences,
): Promise<UserPreferences> {
  const data = await request(
    `${API_URL()}/rest/v1/users/preferences`,
    'POST',
    true,
    JSON.stringify(preferences),
  );
  return data.preferences;
}

export async function putOrganization (organization: Partial<Organization>): Promise<Organization> {
  const data = await request(`${API_URL()}/rest/v1/organizations`, 'PUT', true, JSON.stringify(organization));
  return data.organizations[0];
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function postPasswordReset (passwordReset?: any): Promise<void> {
  await request(`${API_URL()}/rest/v1/users/password`, 'POST', true, JSON.stringify(passwordReset));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function postForgotPasswordStart (passwordReset?: any): Promise<void> {
  await request(`${API_URL()}/rest/v1/external/user/password`, 'POST', true, JSON.stringify(passwordReset));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function putForgotPasswordFinish (passwordReset?: any): Promise<void> {
  await request(`${API_URL()}/rest/v1/external/user/password`, 'PUT', true, JSON.stringify(passwordReset));
}

export async function postUnsubscribe (user): Promise<void> {
  await request(`${API_URL()}/rest/v1/user/unsubscribe`, 'POST', true, JSON.stringify(user));
}

export async function getUsersFromEmailAddresses (emails: string[]): Promise<User[]> {
  if (!emails?.length) return [];

  const data = await request(`${API_URL()}/rest/v1/users/get`, 'POST', true, JSON.stringify(emails));
  return data?.users ?? [];
}

// TODO: remove duplicate function to get user emails original function does not account for partial response, backend should return response in users array in partial response
export async function getUsersFromEmails (emails: string[]): Promise<User[]> {
  const data = await request(`${API_URL()}/rest/v1/users/get`, 'POST', true, JSON.stringify(emails));
  return data.users;
}

export async function postShares (shares: Share[]): Promise<Share[]> {
  const data = await request(`${API_URL()}/rest/v1/shares`, 'POST', true, JSON.stringify(shares));
  return data.shares;
}

export async function putShares (shares: Share[]): Promise<Share[]> {
  const data = await request(`${API_URL()}/rest/v1/shares`, 'PUT', true, JSON.stringify(shares));
  return data.shares;
}

export async function deleteShares (shares: Share[]): Promise<void> {
  await request(`${API_URL()}/rest/v1/shares`, 'DELETE', true, JSON.stringify(shares));
}

export async function postFiles (files: FileModel[]): Promise<FileModel[]> {
  const data = await request(`${API_URL()}/rest/v1/files`, 'POST', true, JSON.stringify(files));
  return data.files;
}

export interface FileAndSigned {
  file: FileModel;
  signed: string;
}

export async function uploadFile (
  file: File,
  tags: string[],
): Promise<FileAndSigned> {
  const formData = new FormData();
  formData.append('file', file);
  const data = await request(
    `${API_URL()}/rest/v1/files/upload?tags=${tags?.join(',')}`,
    'POST',
    true,
    formData,
  );
  return {
    file: data.files[0],
    signed: data.signedLocation,
  };
}

export async function deleteFiles (files: FileModel[]): Promise<void> {
  await request(`${API_URL()}/rest/v1/files`, 'DELETE', true, JSON.stringify(files));
}

interface PostDependenciesResponse {
  dependencies: Dependency[];
  errors: string[];
}

export async function postDependencies (
  deps: Dependency[],
  throughModularObjectId?: string,
): Promise<PostDependenciesResponse> {
  return await request(
    `${API_URL()}/rest/v1/dependencies`,
    'POST',
    true,
    JSON.stringify({
      dependencies: deps,
      throughModularObjectId,
    }),
  );
}

export async function patchDependencies (deps: Dependency[], throughModularObjectId: string) {
  return await request(
    `${API_URL()}/rest/v1/dependencies?throughModularObjectId=${throughModularObjectId}`,
    'PATCH',
    true,
    JSON.stringify(deps),
  );
}

export async function getNotifications (): Promise<Notification[]> {
  const data = await request(`${API_URL()}/rest/v1/notifications`, 'GET', true);
  return data.notifications;
}

// POST v1/external/link
export async function postExternalLink (object: ObjectToLink, forceGen?: boolean): Promise<ObjectToActivateLink> {
  const url = new URL(`${API_URL()}/rest/v1/external/link`);
  if (forceGen) {
    url.search = new URLSearchParams({ forceGen: 'true' }).toString();
  }
  const data = await request(url.toString(), 'POST', true, JSON.stringify(object));

  return data.link;
}

// DELETE v1/external/link
export async function deleteExternalLink (object: ObjectToLink): Promise<void> {
  await request(`${API_URL()}/rest/v1/external/link`, 'DELETE', true, JSON.stringify(object));
}

// POST v1/external/link/activate
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function postExternalLinkActivate (object: ObjectToActivateLink): Promise<any> {
  return await request(
    `${API_URL()}/rest/v1/external/link/activate`,
    'POST',
    true,
    JSON.stringify(object),
  );
}

// POST v1/external/link/access
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function postExternalLinkAccess (object: ObjectToAccess): Promise<any> {
  return await request(`${API_URL()}/rest/v1/external/link/access`, 'POST', true, JSON.stringify(object));
}

export const postIntegration = async (
  integration: HardwareIntegration[],
): Promise<{ approvals: HardwareIntegration[] }> => {
  return await request(`${API_URL()}/rest/v1/hardware/integrations`, 'POST', true, JSON.stringify(integration));
};

// GET rest/v1/hardware/integrations/{objectType}/approvals
export async function getHardwareIntegrationsApprovals (objectType: string): Promise<HardwareIntegration[]> {
  const data = await request(
    `${API_URL()}/rest/v1/hardware/integrations/${objectType}/approvals`,
    'GET',
    true,
  );
  return data.approvals;
}

// GET rest/v1/hardware/integrations/{objectType}
export async function getHardwareIntegrationsByType (objectType: string): Promise<HardwareIntegration[]> {
  const data = await request(
    `${API_URL()}/rest/v1/hardware/integrations/${objectType}`,
    'GET',
    true,
  );
  return data.integrations;
}

// POST rest/v1/hardware/integrations
export async function postHardwareIntegrations (integrations: HardwareIntegration[]): Promise<HardwareIntegration[]> {
  const data = await request(
    `${API_URL()}/rest/v1/hardware/integrations`,
    'POST',
    true,
    JSON.stringify(integrations),
  );
  return data.approvals;
}

// PATCH v1/hardware/integrations
export async function patchHardwareIntegrations (decisions: IntegrationDecision[]): Promise<void> {
  const data = await request(
    `${API_URL()}/rest/v1/hardware/integrations`,
    'PATCH',
    true,
    JSON.stringify(decisions),
  );
  return data.integrations;
}

// GET v1/modular-objects
export async function getModularObjects (): Promise<ModularObject[]> {
  const data = await request(`${API_URL()}/rest/v1/modular-objects`, 'GET', true);
  return data.modularObjects;
}

// POST v1/modular-objects
export async function postModularObject (modularObjects: Array<Partial<ModularObject>>): Promise<ModularObject[]> {
  const data = await request(`${API_URL()}/rest/v1/modular-objects`, 'POST', true, JSON.stringify(modularObjects));
  return data.modularObjects;
}

// PATCH v1/modular-objects
export async function patchModularObject (diff: Array<Partial<Diff>>): Promise<ModularObject[]> {
  const data = await request(`${API_URL()}/rest/v1/modular-objects`, 'PATCH', true, JSON.stringify(diff));
  return data.modularObjects;
}

// DELETE v1/modular-objects
export async function deleteModularObjects (modularObjects: ModularObject[], deleteTasks?: boolean): Promise<void> {
  const url = new URL(`${API_URL()}/rest/v1/modular-objects`);
  if (deleteTasks) {
    url.search = new URLSearchParams({ deleteTasks: 'true' }).toString();
  }
  return await request(url.toString(), 'DELETE', true, JSON.stringify(modularObjects));
}

// GET v1/templates
export async function getTemplates (): Promise<Template[]> {
  const data = await request(`${API_URL()}/rest/v1/templates`, 'GET', true);
  return data.templates;
}

// POST v1/integrations/jira/:projectKey
export async function postJiraProjectKey (projectKey: string): Promise<void> {
  await request(`${API_URL()}/rest/v1/integrations/jira/${projectKey}`, 'POST', true);
}

// DELETE v1/integrations/:provider
export async function deleteIntegration (provider: string): Promise<void> {
  await request(`${API_URL()}/rest/v1/integrations/${provider}`, 'DELETE', true);
}

export async function postCalcTotalMass (
  objectId: string,
  fields: string[],
  mass: number,
  integrations?: HardwareIntegration[],
): Promise<number> {
  const data = await request(
    `${API_URL()}/rest/v1/calculators/total-mass`,
    'POST',
    true,
    JSON.stringify({ objectId, fields, mass, integrations }),
  );
  return data.calculations?.totalMass;
}

export async function postResendVerifyEmail (email: string): Promise<string | boolean> {
  if (!email?.length) return false;

  try {
    return await request(
      `${API_URL()}/rest/v1/external/account/signup/resend-verify`,
      'POST',
      true,
      JSON.stringify({ email }),
    );
  } catch (error) {
    console.error('An error occurred:', error);
    return null;
  }
}

export async function postCreateNewAccount (user: SignupUserModel): Promise<SignupUserModel> {
  return await request(`${API_URL()}/rest/v1/external/account/signup`, 'POST', true, JSON.stringify(user));
}

export async function getCreatedNewAccountUser (
  token: string,
): Promise<Record<string, Record<string, SignupUserModel>>> {
  return await request(`${API_URL()}/rest/v1/external/account/signup/user/${token}`, 'GET', true);
}

export async function deleteDependency (dependencies: string[]): Promise<Dependency[]> {
  const data = await request(`${API_URL()}/rest/v1/dependencies`, 'DELETE', true, JSON.stringify(dependencies));
  return data.dependencies;
}
