import {
  useGetObjectCardUpdatesSectionQuery,
} from '@/components/cards/UpdatesSection/getObjectCardUpdatesSection.generated';
import UpdatesSectionItem from '@/components/cards/UpdatesSection/UpdatesSectionItem';
import { useEffect, useRef } from 'react';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';

const PAGE_SIZE = 10;

// This is the component
export default function UpdatesSection ({ objectID: id, paginationInput }): JSX.Element {
  const { data, fetchMore } = useGetObjectCardUpdatesSectionQuery({
    variables: {
      id,
      paginationInput: {
        offset: 0,
        limit: PAGE_SIZE,
        time: null,
        direction: 'FORWARD',
      },
    },
  });

  const hasNextPage = data?.getObjectCardUpdatesSection?.pageInfo.hasNextPage;

  const currentOffset = useRef(0); // Persists across state updates; but does not trigger re-renders

  const tableRef = useRef<HTMLDivElement>(null);

  const updates = data?.getObjectCardUpdatesSection?.edges;

  // Intersection observer for pagination/scrolling
  const observerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      () => {
        if (!hasNextPage) {
          return;
        }

        const nextOffset = currentOffset.current + PAGE_SIZE;

        void fetchMore({
          variables: {
            id,
            paginationInput: {
              offset: nextOffset,
              limit: PAGE_SIZE,
              direction: 'FORWARD',
              time: null,
            },
          },
        });

        currentOffset.current = nextOffset;
      },
      { threshold: [1] }, // Governs how much of the element needs to be visible before we trigger our event. (0, 0.5, etc...)
    );

    observer.observe(observerRef.current);

    return () => observer.disconnect(); // This runs when the component dismounts.
  }, [hasNextPage, fetchMore, id]); // [] -> run once when component mounts. NO array -> run on all re-render. Populated Array -> Run when one of these things changes.

  // This is the jsx
  return (
    <div className='flex flex-col gap-[10px]' ref={tableRef}>
      {updates?.map(
        (update) => <UpdatesSectionItem key={uuidv5(uuidv4(), update.resourceID)} update={update} />,
      )}
      <div className='flex flex-col gap-[16px]' ref={observerRef} />
    </div>
  );
}
