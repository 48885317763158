import { ONE_DAY } from '@/util/constants';
import { format, isValid, parse, parseISO } from 'date-fns';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getModuleByName } from './lookup.functions';
import { TemplateType } from '@/models/template.model';

const ARBITRARY_MONTH_LENGTH_IN_DAYS = 28;
export function secondsToDhms (totalSeconds: number): string {
  const days = Math.floor(totalSeconds / (3600 * 24));
  const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  return `${days}:${hours}:${minutes}:${seconds}`;
}

export const getMonthsBetweenTwoDates = (
  startDate: Date,
  endDate: Date,
): number => {
  let months: number;
  months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
  months += endDate.getMonth() - startDate.getMonth();
  return months <= 0 ? 0 : months;
};

export const getDaysBetweenTwoDates = (
  startDate: Date,
  endDate: Date,
): number => {
  const utcStart = Date.UTC(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate(),
  );
  const utcEnd = Date.UTC(
    endDate.getFullYear(),
    endDate.getMonth(),
    endDate.getDate(),
  );

  return Math.floor((utcEnd - utcStart) / ONE_DAY);
};

/**
 * @deprecated I'm pretty sure we can remove this since L-Minus Milestones are deprecated
 * Get the milestone due date based on lMinus
 * @param milestone MilestoneModel, milestone to get the due date of
 * @param launchDate Dayjs, relative date used to calculate the due date based on the lminus
 * @returns Dayjs, the due date as a Dayjs
 */
export const getMilestoneDueDate = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  milestone: any,
  launchDate?: dayjs.Dayjs | Date | string,
): dayjs.Dayjs => {

  const shouldSkipDateTypeCheck = milestone?.template?.type === TemplateType.Driver;

  const dateRangeSelection = getModuleByName(milestone?.data, 'Date Range Selection')?.value;

  if (
    (milestone?.staticDueDate || milestone?.targetDate) &&
    (shouldSkipDateTypeCheck || dateRangeSelection === 'Static Dates')
  ) {
    return dayjs(milestone.staticDueDate || milestone?.targetDate);
  }

  // Only use the relative due date if 'L-Minus Dates'
  if (
    (shouldSkipDateTypeCheck || dateRangeSelection === 'L-Minus Dates') &&
    (milestone?.relativeEndMonths || milestone?.relativeEndDays)
  ) {
    return getLminusCalcDueDate(
      milestone?.relativeEndMonths,
      milestone?.relativeEndDays,
      launchDate,
    );
  }

  return null;
};

/**
 * Get the milestone start date based on lMinus
 * @deprecated I'm pretty sure we can remove this since L-Minus Milestones are deprecated
 * @param milestone MilestoneModel, milestone to get the due date of
 * @param launchDate Dayjs, relative date used to calculate the due date based on the lminus
 * @returns Dayjs, the start date as a Dayjs
 */
export const getMilestoneStartDate = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  milestone: any,
  launchDate?: dayjs.Dayjs | Date | string,
): dayjs.Dayjs => {
  const shouldSkipDateTypeCheck = milestone?.template?.type === TemplateType.Driver;
  // Use the static start date if 'Static Dates' OR
  // If no relative start months/days and static start date is set

  const dateRangeSelection = getModuleByName(milestone?.data, 'Date Range Selection')?.value;

  if (
    ((milestone.staticStartDate || milestone?.startDate) &&
      (shouldSkipDateTypeCheck || dateRangeSelection === 'Static Dates')) ||
    ((milestone.staticStartDate || milestone?.startDate) &&
      !(parseInt(milestone?.relativeStartMonths) || parseInt(milestone?.relativeStartDays)))
  ) {
    return dayjs(milestone.staticStartDate || milestone?.startDate);
  }

  if (
    (shouldSkipDateTypeCheck || dateRangeSelection === 'L-Minus Dates') &&
    (parseInt(milestone?.relativeStartMonths) || parseInt(milestone?.relativeStartDays))
  ) {
    return getLminusCalcStartDate(
      milestone?.relativeStartMonths,
      milestone?.relativeStartDays,
      launchDate,
    );
  }

  return null;
};

export const getLminusCalcDueDate = (
  lMinusMonthsDue: number | string = 0,
  lMinusDaysDue: number | string = 0,
  launchDate?: dayjs.Dayjs | Date | string,
): dayjs.Dayjs => {
  if (!lMinusMonthsDue && !lMinusDaysDue) return dayjs(launchDate);

  const dueDateOffset = ARBITRARY_MONTH_LENGTH_IN_DAYS *
    (-lMinusMonthsDue || 0) +
    (-lMinusDaysDue || 0);

  return dayjs(launchDate)
    .startOf('day')
    .add(dueDateOffset, 'days');
};

export const getLminusCalcStartDate = (
  lMinusMonthsStart: number | string = 0,
  lMinusDaysStart: number | string = 0,
  launchDate?: dayjs.Dayjs | Date | string,
): dayjs.Dayjs => {
  if (!lMinusMonthsStart && !lMinusDaysStart) return dayjs(launchDate);

  const startDateOffset = ARBITRARY_MONTH_LENGTH_IN_DAYS *
    (+lMinusMonthsStart || 0) +
    (+lMinusDaysStart || 0);

  return dayjs(launchDate)
    .startOf('day')
    .subtract(startDateOffset, 'days');
};

export function getParsedDateFormat (date: string, dateFormat?: string): string {
  if (!date) return '';

  try {
    let thisDate = parse(date, dateFormat || 'MM/dd/yyyy', new Date());
    if (isValid(thisDate)) {
      return format(thisDate, dateFormat || 'MM/dd/yyyy');
    }

    thisDate = parseISO(date);
    if (isValid(thisDate)) {
      return format(thisDate, dateFormat || 'MM/dd/yyyy');
    }
  } catch (e) {
    return date;
  }

  return date;
}

export function getPrettyDateString (date: dayjs.Dayjs | Date | string): string {
  if (!date) return '';

  dayjs.extend(utc);

  return dayjs(date).calendar(null, {
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: '[Next] dddd',
    lastWeek: '[Last] dddd',
    sameElse: 'MM/DD/YYYY',
  });
}

export function isIsoDate (str) {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.+/.test(str)) return false;
  const d = new Date(str);
  return d instanceof Date && !isNaN(d.getTime()); // valid date
}
