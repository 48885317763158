import type { TypedTypePolicies } from '@/__generated__/apollo-type-policies';
import { InMemoryCache, makeVar } from '@apollo/client';
import { localVariablesTypePolicies } from './localVariables';

export const stateMachine = makeVar({});

const typePolicies: TypedTypePolicies = {
  ...localVariablesTypePolicies,
  Query: {
    fields: {
      // @ts-expect-error - because our graphql is inflexible and robbed of all of it's power
      streamingObjects: {
        read() {
          return stateMachine();
        },
      },
      getRecentActivityByUserId: {
        keyArgs: ['input', ['userId', 'type']],
        merge: naiveMergeNewPage,
      },
      teamMembers: {
        keyArgs: ['input', ['query', ['teamId']]],
        merge: mergeWithOffsetPagination,
      },
      getDependencyCandidatesForObjectId: {
        keyArgs: ['input', ['id', 'filter']],
        merge: mergeWithOffsetPagination,
      },
      getPrograms: {
        keyArgs: ['input', ['paginationInput']],
        merge: mergeWithOffsetPagination,
      },
      getTopLevelModularObjects: {
        keyArgs: ['filters', 'childFilters'],
        merge: mergeWithOffsetPagination,
      },
      getObjectCardUpdatesSection: {
        keyArgs: ['id'],
        merge: mergeWithOffsetPagination,
      },
      getPrismaticJiraIssues: {
        keyArgs: ['paginationInput', ['direction', 'time']],
        merge: mergeWithOffsetPagination,
      },
      getBuilds: {
        keyArgs: ['filters', 'childFilters'],
        merge: mergeWithOffsetPagination,
      },
      opsGetUsers: {
        keyArgs: ['input', 'likeNameSearch', ['paginationInput', 'fetchType']],
        merge: mergeWithOffsetPagination,
      },
      opsGetOrganizations: {
        keyArgs: ['input', ['paginationInput', 'name']],
        merge: mergeWithOffsetPagination,
      },
    },
  },
  ModularObject: {
    fields: {
      children: {
        keyArgs: false, // Keep this here, or else when you're trying to read a fragment from the cache, it won't return because all the filters junk will be cached on the "children" key
      },
    },
  },
};

export const getNewApolloCache = () => new InMemoryCache({ typePolicies });

// This is taken and modified from the Apollo docs referenced function
// https://www.apollographql.com/docs/react/pagination/offset-based#the-offsetlimitpagination-helper
// https://github.com/apollographql/apollo-client/blob/main/src/utilities/policies/pagination.ts#L33-L49
function mergeWithOffsetPagination (existing, incoming, { args }) {
  // Make a shallow copy of the existing edges array
  const merged = existing?.edges ? existing.edges.slice() : [];

  if (incoming?.edges) {
    if (args) {
      // Assume an offset of 0 if args.paginationInput.offset omitted.
      const { offset = 0 } = args?.paginationInput ?? {};
      for (let i = 0; i < incoming.edges.length; ++i) {
        merged[offset + i] = incoming.edges[i];
      }
    } else {
      // It's unusual (probably a mistake) for a paginated field not
      // to receive any arguments, so you might prefer to throw an
      // exception here, instead of recovering by appending incoming
      // onto the existing array.
      console.error(`
        Whoa! this request did not have any expected \`paginationInput\` args.
        This may cause the cache to update incorrectly.
        (We are simply appending the incoming edges to the existing edges)
        Args: ${JSON.stringify(args)}
      `);
      return {
        pageInfo: {
          hasNextPage: false,
        },
        edges: (existing?.edges ?? [])?.push(...incoming.edges),
      };
    }
  }

  return {
    pageInfo: {
      ...incoming.pageInfo,
    },
    edges: merged,
  };
}

// TODO: This is a naive implementation of merging new pages.
// The cursors in recent activity should be changed to offset based pagination
// and this can be removed.
function naiveMergeNewPage (existing, incoming) {
  // If there are existing edges and incoming edges, handle merging them
  if (existing?.edges) {
    const existingRefs = existing.edges.map((edge) => edge.__ref);
    const incomingRefs = incoming.edges.map((edge) => edge.__ref);

    let newEdges = [];

    // Check if any incoming Ids are already in the existing edges
    const shouldUpdateExistingEdges = incomingRefs.some((ref) => existingRefs.includes(ref));

    let updatedExistingEdges = [...existing.edges];
    // If any incoming edges are already in the existing edges,
    // replace the existing edges with the incoming edges

    if (shouldUpdateExistingEdges) {
      updatedExistingEdges = existing.edges.map((edge) => {
        const incomingEdge = incoming.edges.find((incomingEdge) => incomingEdge.__ref === edge.__ref);

        // If found a matching incoming edge, replace the existing edge
        return incomingEdge ?? edge;
      });

      // Filter out any incoming edges that are already in the existing edges
      const newIncomingEdges = incoming.edges.filter((incomingEdge) => !existingRefs.includes(incomingEdge.__ref));

      // Append any new edges to the updated existing edges
      newEdges = [...updatedExistingEdges, ...newIncomingEdges];

      return {
        pageInfo: { ...incoming.pageInfo },
        edges: newEdges,
      };
    }

    // If there is no overlap, return the incoming edges appended to the existing edges
    return {
      ...incoming,
      edges: [...existing.edges, ...incoming.edges],
    };
  } else {
    // If there are no existing edges, just return the incoming
    return incoming;
  }
}
