import { Listbox, Transition } from '@headlessui/react';
import cx from 'classnames';
import useDropDown from './hooks/useDropDown';
import ListBoxButton from './ListBoxButton';
import OptionList from './OptionList';

interface DropDownProps {
  label?: string;
  options: Array<{ value: string; displayText: string; icon?: string }>;
  disabled?: boolean;
  button?: typeof ListBoxButton;
  initialValue?: { displayText: string; value: string; icon?: string };
  optionList?: typeof OptionList;
  hideSelectionHint?: boolean;
  onChange?: (value: string) => void;
  autoRotate?: boolean;
  className?: string;
}

export default function DropDown ({
  options,
  onChange,
  initialValue = null,
  label = null,
  button = ListBoxButton,
  optionList = OptionList,
  disabled = false,
  hideSelectionHint = false,
  autoRotate = false,
  ...rest
}: Readonly<DropDownProps>) {
  /**
   * ==============================================================
   *                     *** PLEASE NOTE ***
   * This component is extremely flexible and is meant to be extended
   * rather than modified. If you need different behavior the best way
   * to accomplish that is to create a new button or option list and
   * pass them in.
   *
   * See PageSizeSelectionDropdownButton.tsx for an example.
   *
   * Please give serious consideration to the existing design before
   * modifying this component. If you do decide to modify it, please
   * call that out in your PR for review.
   *
   * ==============================================================
   */
  const {
    listOptionContainerRef,
    listOptionsRef,
    handleChange,
    selected,
    placeholder,
    iconMap,
    shouldDisplayAbove,
    filteredOptions,
  } = useDropDown(onChange, options, initialValue);

  return (
    <div ref={listOptionContainerRef} {...rest}>
      <span ref={listOptionsRef}>
        {label}
        <Listbox disabled={disabled} value={selected?.value ?? initialValue?.value} onChange={handleChange}>
          {button({ selected, placeholder, initialValue, icons: iconMap })}
          <div className='relative w-full'>
            <Transition
              as='div'
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
              className={cx('absolute w-full z-[9999] overflow-auto shadow-lg', {
                'top-[5px]': !shouldDisplayAbove,
                'bottom-[37px]': autoRotate && shouldDisplayAbove,
              })}
            >
              {optionList({ options: filteredOptions, hideSelectionHint })}
            </Transition>
          </div>
        </Listbox>
      </span>
    </div>
  );
}
