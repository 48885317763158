import UserName from '@/components/history/TimelineEvent/UserName';
import { ProfileImage } from '@/components/ProfileImage/ProfileImage';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { getPrettyDateString } from '@/util/time.functions';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import type { GetObjectCardUpdatesSectionQuery } from './getObjectCardUpdatesSection.generated';

// File is the module

type updatesSectionItem = GetObjectCardUpdatesSectionQuery['getObjectCardUpdatesSection']['edges'][number];

interface updatesSectionItemProps {
  update: updatesSectionItem;
}

// function definition is the component
export default function UpdatesSectionItem ({ update }: updatesSectionItemProps) {
  // TODO - destruct this to pass only id and orgID into UserName()?
  const currentUser = useLoggedInUser();

  // this is the jsx we're returning
  return (
    <div key={uuidv5(uuidv4(), update.resourceID)} className='flex gap-[4px] items-center mx-8'>
      <ProfileImage
        size='small'
        user={{
          organizationId: update.userOrganizationID,
          profileImage: update.userProfileImage,
        }}
      />

      <UserName
        user={{
          id: update.userID,
          organizationId: update.userOrganizationID,
          firstName: update.userFirstName,
          lastName: update.userLastName,
        }}
        currentUser={currentUser}
      />

      <div>{update.action}</div>

      <span
        className='mr-2 text-sm text-gray-ghost'
        data-chromatic='ignore'
      >
        <div title={update.timestamp}>{getPrettyDateString(update?.timestamp)}</div>
      </span>
    </div>
  );
}
