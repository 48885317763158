import type { SubscriptionTypes } from '@/__generated__/types';
import dayjs from 'dayjs';
import { env } from './env.utils';

export enum KeyboardEventNames {
  Backspace = 'Backspace',
  Enter = 'Enter',
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
}

export const defaultEnv = {
  STAGE: 'development',
  API_INTERNAL: '',
  API_PROTOCOL: 'http',
  API_WS_PROTOCOL: 'ws',
  AI_PLATFORM_PROTOCOL: 'http',
  AI_PLATFORM_DOMAIN: 'localhost:8000',
  API_DOMAIN: 'localhost:9090',
  APP_DOMAIN: 'localhost:3000',
  APP_SUBDOMAIN: '',
  COOKIE_DOMAIN: 'localhost',
  JWT_COOKIE_NAME: 'develop-jwt',
  EXPIRATION_INTERVAL: '30000',
  AMPLITUDE_API_KEY: '',
};

export const PAYLOAD_TYPES: string[] = [
  'Telecommunications',
  'Remote Sensing',
  'In-Space Manufacturing',
  'In-Space Transportation',
  'Other',
];

export const PAYLOAD_PHYSICAL_PROPERTY_DIRECTIONS: string[] = [
  'Nadir Facing',
  'Zenith Facing',
  'Other',
  'Not Applicable',
];

export const PAYLOAD_PHYSICAL_PROPERTY_PLACEMENT: string[] = [
  'Internal',
  'External',
  'Other',
  'Not Applicable',
];

export const VEHICLE_MILESTONES = [
  {
    title: 'Engine Hotfire',
    key: 'engineHotfire',
  },
  {
    title: 'Full Mission Duty Cycle engine hotfire (MDC)',
    key: 'fullMDCEngineHotfire',
  },
  {
    title: 'First Stage Burst Test, Presure Proof Test, or equivalent',
    key: 'firstStageBurstPressureTest',
  },
  {
    title: 'Second Stage Burst Test, Presure Proof Test, or equivalent',
    key: 'second_stage_burst_pressure_test',
  },
  {
    title: 'Engine Full Thrust MDC Hotfire',
    key: 'engine_full_thrust_mdc_hotfire',
  },
  {
    title: 'Second Stage Integrated Hotfire',
    key: 'second_stage_integrated_hotfire',
  },
  {
    title: 'Static Fire',
    key: 'static_fire',
  },
  {
    title: 'Orbital Launch Attempt',
    key: 'orbital_launch_attempt',
  },
];

export const LAUNCH_SERVICES_PREFERENCE = 'visible_launch_services';

export const VEHICLE_MILESTONES_MAP = VEHICLE_MILESTONES.reduce((prev, current) => ({
  ...prev,
  [current.key]: current.title,
}), {});

export const ONE_DAY = 24 * 60 * 60 * 1000;
export const ONE_MONTH = ONE_DAY * 30;
export const NOW = dayjs();

export const API_URL = () => `${env('NEXT_PUBLIC_API_PROTOCOL')}://${env('NEXT_PUBLIC_API_DOMAIN')}`;
export const AI_PLATFORM_URL = () =>
  `${env('NEXT_PUBLIC_AI_PLATFORM_PROTOCOL')}://${env('NEXT_PUBLIC_AI_PLATFORM_DOMAIN')}`;
export const APP_URL = () =>
  `${env('NEXT_PUBLIC_API_PROTOCOL')}://${
    env('NEXT_PUBLIC_APP_SUBDOMAIN') ? env('NEXT_PUBLIC_APP_SUBDOMAIN') + '.' : ''
  }${env('NEXT_PUBLIC_APP_DOMAIN')}`;
export const isHTTPS = () => env('NEXT_PUBLIC_API_PROTOCOL') === 'https';

const HOURS = 24;
const MINUTES_IN_HOUR = 60;
const HOURS_LIST = Array.from(Array(HOURS).keys());

export const INDEX_TO_TIME_MAP = [];
export const TIME_TO_INDEX_MAP = {};

HOURS_LIST.forEach((hour) => {
  for (let i = 0; i < MINUTES_IN_HOUR; i++) {
    const time = `${hour < 10 ? '0' : ''}${hour}:${i < 10 ? '0' : ''}${i}`;
    INDEX_TO_TIME_MAP.push(time);
    TIME_TO_INDEX_MAP[time] = INDEX_TO_TIME_MAP.length - 1;
  }
});

export const APPROVAL_STATUS = {
  approved: 'Approved',
  rejected: 'Rejected',
  old: 'Old',
  current: 'Current',
  pending: 'Pending',
};

export const ORGANIZATION_TYPES = {
  spacecraftManufacturer: 'Spacecraft Manufacturer',
  launchVehicleManufacturer: 'Launch Vehicle Manufacturer',
  rideshareAggregator: 'Rideshare Aggregator',
};

export const STATUSES = {
  toDo: 'To Do',
  inProgress: 'In Progress',
  complete: 'Complete',
  waived: 'Waived',
};

export const STATUS_ICONS = {
  'To Do': '/images/to-do-black.svg',
  'In Progress': '/images/in-progress-black.svg',
  Submitted: '/images/in-progress-black.svg',
  Returned: '/images/in-progress-black.svg',
  'Re-Submitted': '/images/in-progress-black.svg',
  Approved: '/images/complete-black.svg',
  'Not required': '/images/to-do-black.svg',
};

export const STATUS_OPTIONS = Object.values(STATUSES);

export const EXPIRATION_INTERVAL = () => Number(env('NEXT_PUBLIC_EXPIRATION_INTERVAL'));

export const OBJECT_TYPES = [
  'modular_object',
  'template',
];

export const ADMIN_ROLE = 'admin';
export const SUPER_ROLE = 'super';
export const USER_ROLE = 'user';
export const MANAGER_ROLE = 'manager';
export const USER_ROLES = [SUPER_ROLE, ADMIN_ROLE, USER_ROLE, MANAGER_ROLE];

export const SUBSCRIPTION_TYPES: Record<string, SubscriptionTypes> = {
  FREE_TRIAL: 'trial',
  BASIC: 'basic',
  PRO: 'pro',
} as const;

export const HUMAN_FRIENDLY_SUBSCRIPTION_PLAN_NAMES: Record<SubscriptionTypes, string> = {
  'trial': 'Free Trial',
  'basic': 'Free Plan',
  'pro': 'Pro Plan',
} as const;

export type USER_SELECTABLE_SUBSCRIPTION_TYPES = typeof SUBSCRIPTION_TYPES.BASIC;

export const DEPLOY_DATE_OF_PRICING_FEATURE = dayjs('2023-11-03T07:00:00Z');

export const INTERNAL_USER_REWARD_TEXT = '+3 days to your trial';
export const EXTERNAL_USER_REWARD_TEXT = '+1 week to your trial';

export const THEME_COLORS = {
  gray: {
    30: 'rgba(13, 24, 21, 0.10)',
    40: '#666666',
    60: '#999999',
    70: '#B3B3B3',
    80: '#CCCCCC',
    90: '#E6E6E6',
    95: '#0D1815',
    98: '#FAFAFA',
    dark: '#00000033',
    label: '#00000060',
    light: '#FFFFFF33',
    bg: '#E2E2E2',
    'light-bg': '#F2F2F2',
    modal: '#fafafa',
    ghost: '#666666',
  },
  primary: {
    dark: '#460A7B',
    hover: '#730fbb',
    DEFAULT: '#7F10D0',
    opaque: '#7F10D099',
    'gradient-step': '#5A0CA2',
  },
  accent: {
    DEFAULT: '#FFCD1C',
  },
  secondary: {
    hover: '#e58331',
    DEFAULT: '#FF9439',
    opaque: '#FF943999',
  },
  tertiary: {
    hover: '#12807b',
    DEFAULT: '#1ABAB2',
    opaque: '#1ABAB299',
    'gradient-step': '#049D95',
  },
  magenta: {
    DEFAULT: '#e41449',
  },
  pink: {
    DEFAULT: '#EF2F95',
    dark: '#DC164F',
    pale: '#f1d4df',
  },
  purple: {
    DEFAULT: '#5020f3',
    icon: '#7B25C6',
  },
  lavender: {
    DEFAULT: '#d7d0f5',
  },
  mud: {
    DEFAULT: '#3a3f49',
  },
  orange: {
    DEFAULT: '#FF9439',
  },
  green: {
    success: '#23B58E',
  },
  blue: {
    DEFAULT: '#32ACF9',
  },
  alluringLight: {
    DEFAULT: '#FFF7D9',
  },
  warning: '#EF2F95',
  white: '#FFFFFF',
  black: '#000000',
};

export const PUBLIC_ROUTES = [
  '/404',
  '/external/[type]/[id]',
  '/file-proxy',
  '/hang-tight',
  '/healthz',
  '/login',
  '/login-amazon',
  '/sso-failed',
  '/password-reset-success',
  '/reset-password/[id]',
  '/signup',
  '/signup/error',
  '/unsubscribe',
  '/signup/verify-account',
  '/welcome-back/[token]',
  '/public-object/[linkId]',
];

export const PRIVATE_ROUTES = [
  '/approvals',
  '/settings',
  '/recent-activity',
  '/schedule',
  '/signup/personal-info',
  '/signup/company-info',
  '/signup/invite-collaborators',
  '/templates',
];

export const ERROR_MESSAGES = {
  DEPENDENCIES: {
    CYCLE_DETECTION: {
      MATCHER: 'edge would create a cycle',
      HUMAN_FRIENDLY_MSG: 'This new dependency creates a loop in your setup. ' +
        'Please review the existing connections between items.',
    },
    ZERO_GAP_DETECTION: {
      MATCHER: 'at least one non-zero gap value must be provided',
      HUMAN_FRIENDLY_MSG: 'A non-zero gap value must be provided. Please ' +
        'provide at least one non-zero value for gap years, gap months, or gap ' +
        'days.',
    },
    DEPENDENCY_GRAPH_HAS_CONSTRAINT_VIOLATION: {
      CODE: 'DEPENDENCY_GRAPH_HAS_CONSTRAINT_VIOLATION',
    },
  },
  MODULAR_OBJECTS: {
    CANNOT_MODIFY_LOCKED_DATE: {
      CODE: 'CANNOT_MODIFY_LOCKED_DATE',
    },
  },
};

export const PLURALIZE_EXCEPTIONS = [
  'memory',
  'spacecraft',
  'propulsion',
];

export const COLLABORATOR_INVITE_EXTRA_DAYS = {
  internal: 3,
  external: 7,
};

export const FILTER_LABELS_WITH_IDS = ['owner', 'assignee', 'parent', 'team', 'collaborator'];
