import { Button } from '@/components/Button/Button';
import type { DriverFragment } from '@/components/Schedule/queries/driverFragment.generated';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { useAppSelector } from '@/state/hooks';
import { useObjectCardContext } from '@/state/ObjectCard.context';
import { selectRootState } from '@/state/selectors';
import getIcon from '@/util/getIcon';
import { getModularObjectTemplateName } from '@/util/lookup.functions';
import cx from 'classnames';

interface DriverParentProps {
  driver: DriverFragment;
  className?: string;
  rootClassName?: string;
}

export default function DriverParent ({ driver, className, rootClassName }: Readonly<DriverParentProps>): JSX.Element {
  const currentUser = useLoggedInUser();
  const state = useAppSelector(selectRootState);
  const currentParent = {
    ...driver?.parent,
  };

  const parentTemplateName = getModularObjectTemplateName(currentParent?.id, state) || null;
  const { openViewObjectCardDrawer } = useObjectCardContext();

  const openParent = (): void => {
    openViewObjectCardDrawer({ modularObjectId: currentParent?.id });
  };
  const hasAssignee = Boolean(driver?.assigneeId);
  const isExternalOwner = currentUser?.organizationId !== driver?.owner?.organizationId;
  const isExternalAssignee = currentUser?.organizationId !== driver?.assignee?.organizationId && hasAssignee;
  const isExternal = isExternalOwner || isExternalAssignee;

  return (
    <Button
      className={cx('flex items-center font-normal font-effra text-left', rootClassName)}
      innerClassName='flex gap-3 items-center'
      onClick={openParent}
    >
      {currentParent?.name && (
        <div className='flex items-center w-[12px] h-[12px] aspect-square'>
          {getIcon(parentTemplateName)}
        </div>
      )}
      <span
        className={cx('truncate effra-14', className, {
          'text-black': !isExternal,
          'text-tertiary': isExternal,
        })}
      >
        {!currentParent?.name ? '–' : currentParent?.name}
      </span>
    </Button>
  );
}
