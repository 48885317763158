import type { Approval } from '@/__generated__/types';
import PrismaticProvider from '@/components/Contexts/prismatic.context';
import ApprovalRow from '@/components/tables/Approvals/ApprovalRow/ApprovalRow';

interface ApprovalRowProps {
  approvals: Approval[];
  isLoading: boolean;
  onSubmitApproval: (approval: Approval) => Promise<void>;
  onSubmitDenial: (approval: Approval) => Promise<void>;
}

export function ApprovalRows (
  { approvals, isLoading, onSubmitApproval, onSubmitDenial }: ApprovalRowProps,
): JSX.Element {
  return (
    <>
      {approvals.filter(Boolean).map((approval, idx) => (
        <PrismaticProvider
          isOwner={true}
          objectId={approval.referenceId}
          templateType={['Tasks', 'Requirements', 'Milestones', 'Components'].includes(approval.referenceType)
            ? 'task'
            : 'object'}
          key={`${approval.id}-${idx}`}
        >
          <ApprovalRow
            approval={approval}
            isSendingApproval={isLoading}
            onApprove={async () => {
              await onSubmitApproval(approval);
            }}
            onDeny={async () => {
              await onSubmitDenial(approval);
            }}
          />
        </PrismaticProvider>
      ))}
    </>
  );
}
