// FIXME: Incompatible with our TS Config module resolution as "node", updating to "nodenext" conflicts with apollo codegen
// import { faDeployer, faHardware, faLaunchVehicle, faMission, faProgram } from '@awesome.me/kit-5e5d41a4d3/icons';

import DeployerIcon from '@/components/Icons/DeployerIcon';
import HardwareIcon from '@/components/Icons/HardwareIcon';
import LaunchVehicleIcon from '@/components/Icons/LaunchVehicleIcon';
import MissionIcon from '@/components/Icons/MissionIcon';
import ProgramIcon from '@/components/Icons/ProgramIcon';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBarsProgress,
  faBlock,
  faBolt,
  faBorderOuter,
  faBox,
  faCamera,
  faCar,
  faCircleDashed,
  faCode,
  faConveyorBeltArm,
  faDisplay,
  faEngine,
  faFlagSwallowtail,
  faGamepad,
  faGaugeHigh,
  faHandsBound,
  faHardDrive,
  faJoystick,
  faLightbulbGear,
  faMemory,
  faMicrochip,
  faPipeValve,
  faPlaneDeparture,
  faRhombus,
  faRobot,
  faRocketLaunch,
  faRouter,
  faShield,
  faShip,
  faSkeleton,
  faStarfighterTwinIonEngine,
  faTankWater,
  faTemperatureSnow,
  faTire,
  faTowerBroadcast,
  faTransformerBolt,
  faUserPlus,
  faWalkieTalkie,
  type IconDefinition,
} from '@fortawesome/sharp-solid-svg-icons';

import pluralize from 'pluralize';
// const DEFAULT_ICON = faHardware;

const TEMPLATE_NAME_TO_ICON_CLASS: Record<string, IconDefinition> = {
  'adduser': faUserPlus,
  'antenna': faTowerBroadcast,
  // 'assembly': faHardware,
  'auto': faCar,
  'avionics system': faGaugeHigh,
  'chassis': faSkeleton,
  'communications system': faWalkieTalkie,
  'component': faBolt,
  'control interface': faJoystick,
  'cooling system': faTemperatureSnow,
  // 'deployer': faDeployer,
  'display': faDisplay,
  'effector': faHandsBound,
  'electrical power system': faTransformerBolt,
  'fuel tank': faTankWater,
  'generic hardware': faBlock,
  // 'generic program': faHardware,
  'generic system': faLightbulbGear,
  'ground contact': faTire,
  // 'launch vehicles': faLaunchVehicle,
  'l-minus milestone': faRhombus,
  'memory': faMemory,
  'microprocessor': faMicrochip,
  'milestone': faRhombus,
  // 'mission': faMission,
  'motor': faEngine,
  'motor controller': faGamepad,
  'payload': faHardDrive,
  'plane': faPlaneDeparture,
  'printed circuit board': faMicrochip,
  // 'program': faProgram,
  'project': faBarsProgress,
  'propulsion': faRocketLaunch,
  'receiver': faRouter,
  'requirement': faShield,
  'robot': faRobot,
  'robotic arm': faConveyorBeltArm,
  'sensor': faCamera,
  'separation mechanism': faCircleDashed,
  'ship': faShip,
  'software': faCode,
  // 'spacecraft': faHardware,
  'space station accommodation': faStarfighterTwinIonEngine,
  'structure': faBorderOuter,
  'task': faFlagSwallowtail,
  'valve': faPipeValve,
  'widget': faBox,
};

// TODO: Replace this with FontAwesome kit icons
const TEMPLATE_NAME_TO_CUSTOM_ICON = {
  'deployer': DeployerIcon,
  'hardware': HardwareIcon,
  'launch vehicle': LaunchVehicleIcon,
  'mission': MissionIcon,
  'program': ProgramIcon,
};

export default function getIcon (name = '', className?: string): JSX.Element {
  const normalizedTemplateName = pluralize.singular(name.toLowerCase());

  // Check if the normalizedTemplateName is in the TEMPLATE_NAME_TO_CUSTOM_ICON map
  const CustomIcon = TEMPLATE_NAME_TO_CUSTOM_ICON[normalizedTemplateName];
  if (CustomIcon) {
    return <CustomIcon data-testid={`${normalizedTemplateName}-icon`} height='1em' width='1em' className={className} />;
  }

  // Check if the normalizedTemplateName is in the TEMPLATE_NAME_TO_ICON_CLASS map
  const icon = TEMPLATE_NAME_TO_ICON_CLASS[normalizedTemplateName];
  if (icon) {
    return <FontAwesomeIcon icon={icon} className={className} />;
  }

  return <HardwareIcon data-testid='default-icon' height='1em' width='1em' className={className} />;
}
