import { useUpdateAttributeValue } from '@/hooks/useUpdateAttributeValue';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import ControlledDatePicker, { type ControlledDatePickerProps } from './ControlledDatePicker';

interface ControlledDatePickerWithUpdateProps extends Omit<ControlledDatePickerProps, 'inputProps'> {
  isNotAttribute?: boolean;
  inputProps?: Omit<ControlledDatePickerProps['inputProps'], 'onChange'>;
}

export default function ControlledDatePickerWithUpdate ({
  name,
  isNotAttribute,
  inputProps,
  ...props
}: ControlledDatePickerWithUpdateProps): JSX.Element {
  const { setValue } = useFormContext();
  const { debouncedUpdateValue } = useUpdateAttributeValue();

  const handleChange = useCallback((dateValue: string | React.ChangeEvent<HTMLInputElement>) => {
    const newValue = dateValue instanceof Date
      ? dateValue.toISOString()
      : typeof dateValue === 'string'
      ? dateValue
      : dateValue.target.value;

    setValue(name, newValue, { shouldDirty: true });
    if (isNotAttribute) return;

    void debouncedUpdateValue({ newFieldValue: newValue, attributeName: name });
  }, [debouncedUpdateValue, isNotAttribute, name, setValue]);

  return (
    <ControlledDatePicker
      name={name}
      {...props}
      inputProps={{
        ...inputProps,
        onChange: handleChange,
      }}
    />
  );
}
