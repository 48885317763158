import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkLarge } from '@fortawesome/sharp-solid-svg-icons';
import cx from 'classnames';

export interface FormContainerProps {
  content: React.ReactNode;
  buttons?: React.ReactNode;
  errors?: React.ReactNode;
  headerText: string | React.ReactNode;
  previousSelection?: string | React.ReactNode;
  handleSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  onClose?: () => void;
  width?: string;
  styleOptions?: string;
  className?: string;
  stickyButtons?: boolean;
}

export default function FormContainer ({
  headerText,
  content,
  onClose,
  previousSelection = null,
  buttons = null,
  errors = null,
  handleSubmit = () => {},
  styleOptions = '',
  stickyButtons = false,
}: Readonly<FormContainerProps>): JSX.Element {
  return (
    <form
      onSubmit={handleSubmit}
      className={cx('relative bg-gray-modal', styleOptions, {
        'p-10': !stickyButtons,
        'p-10 pb-0': stickyButtons,
      })}
    >
      <h1 className='capitalize effra-20'>{headerText}</h1>
      {/* // ! put this in another file */}
      {previousSelection
        ? (
          <div className='text-primary effra-14 font-bold flex items-center gap-3 before:content-[url("/images/deployer-primary.svg")] before:block before:w-[25px] before:h-[25px] mt-7 mb-10'>
            {previousSelection}
          </div>
        ) :
        null}
      {onClose && (
        <button
          type='button'
          onClick={onClose}
          className='absolute top-8 right-8'
        >
          <FontAwesomeIcon icon={faXmarkLarge} height='12' width='12' className='text-gray-40' />
        </button>
      )}
      {content}
      <div
        className={cx('flex gap-2', {
          'sticky bottom-0 pt-8 pb-8 bg-inherit': stickyButtons,
          'mt-8': !stickyButtons,
        })}
      >
        {stickyButtons && (
          <div className='absolute top-0 w-full h-2 bg-gradient-to-b to-transparent pointer-events-none from-black/5' />
        )}
        {buttons}
      </div>
      {errors}
    </form>
  );
}
