const FIELD_TO_DISPLAY = {
  name: 'Name',
  description: 'Description',
  type: 'Driver Type',
  subDriverType: 'Nested Driver Type',
  resourceCost: 'Resource Cost',
  startDate: 'Start Date',
  targetDate: 'Due Date',
  status: 'Status',
  visibility: 'Visibility',
  assignee: 'Assignee',
  collaboratorEmail: 'Collaborator Email',
  collaboratorRole: 'Collaborator Role',
  '': 'Do Not Map',
};

export const REQUIRED_FIELDS: MappableDriverField[] = ['name'];

export type MappableDriverField = keyof typeof FIELD_TO_DISPLAY;
export const DRIVER_FIELD_OPTIONS: Record<MappableDriverField, string> = { ...FIELD_TO_DISPLAY };
export const DISPLAY_TO_DRIVER_FIELD: Record<string, MappableDriverField> = Object.entries(FIELD_TO_DISPLAY)
  .reduce((acc, [key, value]) => {
    acc[value.toLowerCase()] = key;
    return acc;
  }, {});
export type DriverMap = Record<string, MappableDriverField>;

export const TYPE_OPTIONS = [
  'Contract',
  'Contract Modification',
  'Deliverable',
  'Integration',
  'Meeting',
  'Payment',
  'Receiving',
] as const;
export type DriverType = typeof TYPE_OPTIONS[number];
/**
 * @deprecated This array should not be used.  Use src/models/driver.model.ts:TaskStatus instead.
 */
export const STATUS_OPTIONS = ['To Do', 'In Progress', 'Complete', 'Waived'];
export type StatusType = typeof STATUS_OPTIONS[number];
export const VISIBILITY_OPTIONS = ['Private', 'Company', 'Global'];
export type VisibilityType = typeof VISIBILITY_OPTIONS[number];
