import { type ModuleType } from '@/components/modules/utils/module.constants';
import pluralize from 'pluralize';

export interface Template {
  id?: string;
  version?: number;
  type?: TemplateType;
  name?: string;
  description?: string;
  imagePath?: string;
  pageColumns?: string[];
  modifiedAt?: string;
  deletedAt?: string;
  createdAt?: string;
  createdById?: string;
  ownerId?: string;
  isIntegratable?: boolean;
  modules?: Record<string, Module>;
  visibility?: string;
  subType?: TemplateSubType;
}

export interface Module {
  name?: string;
  module?: Record<string, Module>;
  type?: ModuleType;
  displayName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties?: Record<string, any>;
  modules?: Record<string, Module>;
  value?: string;
  valueId?: string;
  showInList?: boolean;
  order?: number;
}

export enum TemplateType {
  Build = 'build',
  Driver = 'driver',
  Program = 'program',
}

export enum TemplateSubType {
  Milestone = 'milestone',
  Requirement = 'reqver',
  Task = 'task',
  Component = 'component',
}

export enum TemplateDriverNames {
  Milestone = 'Milestones',
  Requirement = 'Requirements',
  Task = 'Tasks',
  Component = 'Components',
}

export const DRIVER_TYPES = [
  'Milestone',
  'Requirement',
  'Task',
  'Component',
];

export const PLURALIZED_DRIVER_TYPES = DRIVER_TYPES.map(type => pluralize.plural(type));

export const DRIVER_NAME_TO_ABBR = {
  [TemplateDriverNames.Milestone]: 'mile',
  [TemplateDriverNames.Requirement]: 'req',
  [TemplateDriverNames.Task]: 'task',
  [TemplateDriverNames.Component]: 'cpt',
};

export const UNABBREVIATED_DRIVER_SUB_TYPE = {
  [TemplateSubType.Milestone]: 'Milestone',
  [TemplateSubType.Requirement]: 'Requirement',
  [TemplateSubType.Task]: 'Task',
  [TemplateSubType.Component]: 'Component',
};

export enum ScheduleStatus {
  Complete = 'complete',
  InProgress = 'in progress',
  ToDo = 'todo',
}
