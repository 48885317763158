import { ColumnHeader } from '@/components';
import { Transition } from '@headlessui/react';
import classnames from 'classnames';

export interface CardColumn {
  title: string;
  className: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actions?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any;
  hide?: boolean;
  scrollShadow?: boolean;
  headerClassName?: string;
  contentClassName?: string;
  overlayClassName?: string;
}

interface CardColumnsProps {
  columns: CardColumn[];
  className?: string;
}

export function CardColumns (props: CardColumnsProps) {
  const { columns, className } = props;
  return (
    <div className={`flex flex-col overflow-hidden grow relative ${className}`}>
      <div
        className={classnames(
          { flex: columns?.length > 1 },
          'shadow-[0_3px_8px_0px_rgba(0,0,0,0.1)]',
        )}
      >
        {columns.map((column, idx) => (
          <ColumnHeader
            key={`${column.title}-${idx}`}
            title={column.title}
            className={classnames(column.className, column.headerClassName)}
            hide={column.hide}
            actions={column.actions}
          />
        ))}
      </div>

      <div className={classnames({ 'flex grow': columns?.length > 1 }, 'border-t overflow-hidden')}>
        {columns.map((column, idx) => (
          <Transition
            as='div'
            key={`${column.title}-${idx}`}
            show={!column.hide}
            enter='transition-opacity duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity duration-150'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
            className={classnames(
              {
                [column.contentClassName]: column.contentClassName,
                [column.className]: column.className,
                'bottom-shadow': column.scrollShadow,
              },
              'shrink-0 grow-0 border-r no-scrollbar overflow-auto h-full',
            )}
          >
            {column.content}
          </Transition>
        ))}
      </div>
      <div
        id='card-columns-overlay'
        className='flex absolute w-full h-full pointer-events-none z-[100]'
      >
        {columns.map((column, idx) => (
          <div
            key={`${column.title}-${idx}`}
            className={classnames(
              {
                [column.overlayClassName]: column.overlayClassName,
                [column.className]: column.className,
              },
              'shrink-0 grow-0 border-r no-scrollbar overflow-auto h-full',
            )}
          />
        ))}
      </div>
    </div>
  );
}
