export interface ProgramOptionProps {
  title: string;
  itemNum: number;
  onClick?: () => void;
  testId?: string;
}

const numBgColors = [
  'bg-orange/10 text-orange',
  'bg-blue/10 text-blue',
  'bg-purple-icon/10 text-purple-icon/80',
  'bg-green-success/10 text-green-success',
  'bg-accent/10 text-accent',
];
export default function ProgramOption ({ itemNum, title, onClick, testId = 'program-option' }) {
  return (
    <div
      className='flex items-center transition-all border shadow-md bg-white rounded cursor-pointer p-[16px] hover:bg-gray-98 border-gray-80 max-h-[70px] active:shadow-none'
      onClick={onClick}
      data-testid={testId}
    >
      <div
        className={`flex items-center justify-center px-3 py-1 text-center mr-4 font-bold rounded-full effra-18 ${
          numBgColors[itemNum]
        }`}
      >
        <span className='w-[18px]'>{itemNum + 1}</span>
      </div>
      <h3 className='font-medium text-gray-900 effra-16'>{title}</h3>
    </div>
  );
}
