import { stateMachine } from '@/apollo/cache';
import { streamedObjectUpdates } from '@/apollo/localVariables';
import { addToast, addToastError } from '@/components/Toast/utils';
import { useLoggedInUser } from '@/hooks/useLoggedInUser';
import { TemplateType } from '@/models/template.model';
import { AI_PLATFORM_URL } from '@/util/constants';
import { env } from '@/util/env.utils';
import { getCookie } from 'cookies-next';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { useCallback, useState } from 'react';

interface TargetDateSelectorProps {
  selectionState: {
    title: string;
    goal: string;
    targetDate: string;
  };
  closeModal: () => void;
}
interface ValidationResponse {
  isValid: boolean;
  errorMessage: string;
  isoDate: string | null;
  plainDate: string | null;
}

// Simple date validation
function validateDate (dateString: string = ''): ValidationResponse {
  // Check format and parse date
  const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
  const isFormatValid = dateRegex.test(dateString);

  // Extract parts
  const parts = dateString.split('/');
  const month = parseInt(parts[0] || '0', 10);
  const day = parseInt(parts[1] || '0', 10);
  const year = parseInt(parts[2] || '0', 10);

  const currentYear = new Date().getFullYear();
  const parsedDate = dayjs(dateString, 'MM/DD/YYYY');

  // Define validation rules with their error messages using a Map
  const validationRulesMap = new Map([
    [isFormatValid, 'Please enter date in MM/DD/YYYY format'],
    [month >= 1 && month <= 12, 'Month must be between 01 and 12'],
    [(month !== 2) || (month === 2 && day >= 1 && day <= (year % 4 === 0 ? 29 : 28)), 'Invalid day for February'],
    [
      ((month === 4 || month === 6 || month === 9 || month === 11) && day >= 1 && day <= 30) ||
      ((month === 1 || month === 3 || month === 5 || month === 7 || month === 8 || month === 10 || month === 12) &&
        day >= 1 && day <= 31) ||
      (month === 2),
      'Invalid day for this month',
    ],
    [year >= currentYear, `Year must be ${currentYear} or later`],
    [parsedDate.isValid(), 'Please enter a valid date'],
  ]);

  const validationResponse = {
    isValid: true,
    errorMessage: '',
    isoDate: parsedDate.isValid() ? parsedDate.toISOString() : null,
    plainDate: dateString,
  };

  for (const [condition, message] of validationRulesMap) {
    if (!condition) {
      validationResponse.errorMessage = message;
      validationResponse.isValid = false;
      validationResponse.isoDate = null;
      validationResponse.plainDate = null;
      break;
    }
  }

  // Return validation result
  return validationResponse;
}

// Convert MM/DD/YYYY to YYYY-MM-DD format for API
function convertToISODateString (dateString: string): string {
  const parts = dateString.split('/');
  return `${parts[2]}-${parts[0]}-${parts[1]}`;
}

interface ProgramRequest {
  programTitle: string;
  programGoal: string;
  targetDate: string;
}

function mapDescendantsToParent (root, components, parentId) {
  if (!root || !components.length) return root;

  if (root.id === parentId) {
    root.children = [...root.children, ...components];
    return root;
  }

  root.children.forEach(child => {
    mapDescendantsToParent(child, components, parentId);
  });

  return root;
}

async function submitProgramRequest (
  programRequest: ProgramRequest,
  user,
  setLoading: (loading: boolean) => void,
) {
  try {
    const response = await fetch(`${AI_PLATFORM_URL()}/api/v1/chat/create_schedule/stream`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getCookie(env('NEXT_PUBLIC_JWT_COOKIE_NAME'))}`,
      },
      body: JSON.stringify({
        project_title: programRequest.programTitle,
        project_goal: programRequest.programGoal,
        target_date: programRequest.targetDate,
      }),
    });
    setLoading(false);

    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    function augmentComponent (component, template_type) {
      const templateName = template_type === TemplateType.Driver
        ? 'Tasks'
        : 'Generic Program';

      return {
        __typename: 'ModularObject',
        isStreaming: true,
        template: {
          id: component.template_id,
          name: templateName,
          type: template_type,
          __typename: 'Template',
        },
        deactivated: false,
        ownerId: user.id,
        dependencies: [],
        duration: {
          startDate: component.start_date,
          targetDate: component.target_date,
        },
        owner: {
          __typename: 'User',
          id: component.owner_id,
          email: user.email,
          organizationId: user.organizationId,
        },
        visibility: 'Private',
        children: [],
        parent: null,
        permission: 'OWNER',
        ...Object.fromEntries(
          Object.entries(component).map(([key, value]) => [
            key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase()),
            value,
          ]),
        ),
      };
    }

    let root;

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      try {
        const chunk = decoder.decode(value, { stream: true });
        if (chunk.includes('complete')) break;

        const jsonStr = chunk.startsWith('data: ') ? chunk.slice(6) : chunk;
        const data = JSON.parse(jsonStr);
        const augmentedComponents = data?.components.map(
          component => {
            const augmented = augmentComponent(component, data?.template_type);
            // @ts-expect-error - this exists
            stateMachine({ ...stateMachine(), [augmented?.id]: augmented });
            return augmented;
          },
        );

        if (data?.event === 'root_component_created') {
          const [rawRoot] = data?.components ?? [];
          root = augmentComponent(rawRoot, data?.template_type);
        } else {
          mapDescendantsToParent(root, augmentedComponents, data?.parent_id);
        }

        streamedObjectUpdates([root]);
      } catch (parseError) {
        console.error('Error parsing streaming response:', parseError);
      }
    }
  } catch (error) {
    setLoading(false);
    addToastError('Error generating program. Please try again.');
  }
}

export default function TargetDateSelector ({ selectionState, closeModal }: TargetDateSelectorProps) {
  const [targetDate, setTargetDate] = useState('');
  const [dateError, setDateError] = useState('');
  const [, setValidIsoDate] = useState<string | null>(null);
  const [validPlainDate, setValidPlainDate] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const loggedInUser = useLoggedInUser();

  // Debounced validation - only runs when user stops typing
  const debouncedValidate = debounce((value: string) => {
    // Just validate the date as entered - no formatting
    const result = validateDate(value);
    setDateError(result.errorMessage);
    setValidIsoDate(result.isoDate);
    setValidPlainDate(result.plainDate);
  }, 500);

  // Handle input changes
  const handleDateChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;

    // Limit to 10 characters maximum (MM/DD/YYYY)
    if (input.length > 10) return;

    // Update input value immediately
    setTargetDate(input);

    // Validate when user stops typing
    debouncedValidate(input);
  }, [debouncedValidate]);

  const handleGenerateClick = () => {
    if (validPlainDate && !loading) {
      setLoading(true);
      // Convert the MM/DD/YYYY format to YYYY-MM-DD for the API
      const isoFormatDate = convertToISODateString(validPlainDate);

      const request = {
        programTitle: selectionState.title,
        programGoal: selectionState.goal,
        targetDate: isoFormatDate, // Send in YYYY-MM-DD format
      };

      submitProgramRequest(request, loggedInUser, setLoading);
      closeModal();
      addToast('We are working on your program. It will be ready in a few minutes.');
    }
  };

  return (
    <div className='p-8 mx-auto bg-white'>
      <h1 className='mb-6 font-bold text-gray-900 effra-24'>When does this need to be done?</h1>
      <p className='mb-10 text-xl leading-relaxed text-gray-600'>
        Select a target completion date and we&apos;ll build your schedule backwards from there. The base program will
        be created on your timeline, it can then be modified to your liking.
      </p>

      <div className='flex gap-4 mt-8 md:flex-row'>
        <div className='relative w-full max-w-[300px]'>
          <label
            htmlFor='target-date'
            className='flex items-center h-[32px] p-4 bg-white border border-gray-300 rounded cursor-pointer'
          >
            <span className='mr-3 text-gray-400'>
              <i className='fa-sharp fa-regular fa-calendar' />
            </span>
            <input
              type='text'
              id='target-date'
              value={targetDate}
              onChange={handleDateChange}
              className='w-full outline-none bg-transparent h-[32px]'
              placeholder='MM/DD/YYYY'
            />
          </label>
          {dateError && <p className='mt-2 text-red-500'>{dateError}</p>}
        </div>

        <button
          type='button'
          className='rounded px-[16px] py-[8px] tracking-widest text-white font-bold text-base h-[32px] transition-colors bg-primary hover:bg-primary-hover whitespace-nowrap'
          onClick={handleGenerateClick}
          disabled={!validPlainDate || loading}
        >
          {loading ? 'GENERATING...' : 'GENERATE PROGRAM'}
        </button>
      </div>
    </div>
  );
}
